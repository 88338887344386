import { IStringKeyObject } from "../Base/AppTypes";

export function removeKey(obj: any, key: string) {
    const next = { ...obj };

    delete next[key];

    return next;
}


type TKeyFromObjCallback<T> = (item: T) => string | number;
export function groupBy<T>(arr: T[], cb: TKeyFromObjCallback<T>) {
    const obj: IStringKeyObject<T[]> = {};

    for(let v of arr) {
        let value = cb(v);
        //value = typeof value === "string" ? value : String(value);
        const groupKey = cb(v);
        if(!(groupKey in obj)) {
            obj[groupKey] = [];
        }

        obj[groupKey].push(v);
    }

    return obj;
}

export function mapToObj<T>(arr: T[], cb: TKeyFromObjCallback<T>) {
    const obj: IStringKeyObject<T> = {};

    for(let v of arr) {
        let value = cb(v);
        //value = typeof value === "string" ? value : String(value);
        const groupKey = cb(v);
        obj[groupKey] = v;
    }

    return obj;
}



export interface IFilterSplit<T> {
    matched: T[];
    unmached: T[];
}

type TFilterPredicate<T> = (value: T, index: number, array: T[]) => boolean;
export function filterSplit<T>(arr: T[], callback: TFilterPredicate<T>) {
    const filterSplit: IFilterSplit<T> = { matched: [], unmached: [] };

    for(let k in arr) {
        const v = arr[k];
        if(callback(v, k as unknown as number, arr)) {
            filterSplit.matched.push(v);
        } else {
            filterSplit.unmached.push(v);
        }
    }

    return filterSplit;
}


export function flatMap<T>(arrayOfArray: any[][]) {
    const flat: any[] = [];
    for(let array of arrayOfArray) {
        flat.push(...array);
    }

    return flat;
}

