/**
 * TODO: Documentation
 */
import * as React from "react";
import {Table} from "semantic-ui-react";
import {IKeyGenerator, KeyGenerator} from "../Utils/Generators";
import {CSSProperties} from "react";
import {RenderElement} from "../Globals";

export interface IHeaderCell {
    referenceKey: string;
    text: string;
    style?: CSSProperties;
}

export interface IDataCell {
    //headerReferenceKey?: string;
    referenceKey: string;
    value: string;
    style?: CSSProperties;

    jsxRef?: RenderElement;
    meta?: any;
    onClick?: Function;
}

export interface IRowContainer {
    dataCellList: IDataCell[];
    style?: CSSProperties;
}

export interface IBodyContainer {
    rowList: IRowContainer[];
}

export interface IMappableBodyData {
    [key: string]: any;
}

export interface IExtendedTable {
    headerItemList: IHeaderCell[];
    bodyContainerList?: IBodyContainer[];
    bodyContainer?: IBodyContainer;

    style?: CSSProperties;
}

interface IProps extends IExtendedTable {

}

interface IState {

}

type PropsImpl = IProps;
type StateImpl = IState;
class ExtendedTableImpl extends React.PureComponent<PropsImpl, StateImpl>  {
    keyGenerator: IKeyGenerator<string>;
    constructor(props: PropsImpl) {
        super(props);

        this.keyGenerator = new KeyGenerator();
    }

    buildHeader() {
        const headerItemListRef = this.props.headerItemList;
        const headerItemListLen = headerItemListRef.length;
        const headerList: RenderElement[] = [];
        for(let i = 0; i < headerItemListLen; i++) {
            const headerItem = headerItemListRef[i];
            headerList.push(
                <Table.HeaderCell style={headerItem.style} key={headerItem.referenceKey}>
                    {headerItem.text}
                </Table.HeaderCell>
            )
        }

        return (
            <Table.Row>
                {headerList}
            </Table.Row>
        );
    }

    buildBodyContainer(bodyContainer: IBodyContainer) {
        const rowListRef = bodyContainer.rowList;
        const rowListLen = rowListRef.length;

        const tableRowList: RenderElement[] = new Array(rowListLen);
        for(let i = 0; i < rowListLen; i++) {
            const rowItemRef = rowListRef[i];
            const dataCellListRef = rowItemRef.dataCellList;
            const dataCellListLen = dataCellListRef.length;

            const tableCellList: RenderElement[] = new Array(dataCellListLen);
            for(let j = 0; j < dataCellListLen; j++) {
                const dataCellItem = dataCellListRef[j];
                let contentOfCell: RenderElement | string =
                    dataCellItem.jsxRef ? dataCellItem.jsxRef : dataCellItem.value;

                tableCellList.push(
                    <Table.Cell style={dataCellItem.style}
                                onClick={dataCellItem.onClick}
                                key={dataCellItem.referenceKey}>
                        {contentOfCell}
                    </Table.Cell>
                );
            }

            tableRowList.push(
                <Table.Row key={i}>
                    {tableCellList}
                </Table.Row>
            )
        }

        return tableRowList;
    }

    buildBody() {
        const propRef = this.props.bodyContainer;
        if(propRef !== undefined) {
            return (
                <Table.Body>
                    {this.buildBodyContainer(propRef)}
                </Table.Body>
            );
        }

        const propRefList = this.props.bodyContainerList;
        if(propRefList === undefined) {
            return null;
        }

        const length = propRefList.length;
        const bodyList: RenderElement[] = [];
        for(let i = 0; i < length; i++)
        {
            const container = propRefList[i];
            bodyList.push(
                <Table.Body key={i}>
                    {this.buildBodyContainer(container)}
                </Table.Body>
            );
        }

        return bodyList;
    }

    render() {
        const header = this.buildHeader();
        const headerJsx = (
            <Table.Header>
                {header}
            </Table.Header>
        );

        const bodyList = this.buildBody();
        const style: CSSProperties = {};
        style["overflow"] = "scroll";
        //style["display"] = "block";

        const table = (
            <Table celled={true} style={{...this.props.style, ...style}}>
                {headerJsx}
                {bodyList}
            </Table>
        );

        return table;
    }
}

export const ExtendedTable = ExtendedTableImpl;