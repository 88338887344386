import * as React from "react";
import {boolToString, applyToEachKey, applyToFilteredKeys} from "../../Utils/Helpers";
import {Table, Button, Segment, ButtonProps, Checkbox, CheckboxProps, Label} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import {IReport, IReportFragment} from "../../Network/ResponseTypes";
import * as moment from "moment";
import {DATE_ONLY_FORMAT, RenderElement} from "../../Globals";
import {IDataProps} from "../../Base/Semantic";
import {RouteComponentProps} from "react-router";
import {getDateOnlyFromDateTimeString, getDayFromDateOnlyString, transformMinutesToHoursText} from "../../Utils/DateTimeUtils";
import {makeHeaderCell, makeExpressTable} from "../../Utils/TableUtils";
import {DateTable} from "../../Components/DateTable";
import {AdvancedDateTable} from "../../Components/AdvancedDateTable";
import {IDataCell, IHeaderCell} from "../../Components/ExtendedTable";
import { BaseReportTimePage, IPreparedDateControlData, IDateControlDataContainer } from "../../Components/BaseReportTimePage";


interface IOverviewData {
    [key: string]: number;
}


interface IProps {

}

interface IState extends IDateControlDataContainer {
    data: IOverviewData[];
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class OverviewPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {data: [], dateControlData: this.getDefaultDateControlState()};
    }

    componentDidMount(): void {
        const uiSegment = document.querySelector(".ui.segment")!;
        uiSegment.classList.add("sticky-table-segment");

        const body = document.querySelector("body")!;
        body.classList.add("sticky-table-body");

        const table = document.querySelector(".ui.celled.table")!;
        table.classList.add("sticky-table-table");
        table.setAttribute("style", "");

        const tableHead = document.querySelector(".ui.celled.table > thead")!;
        tableHead.classList.add("sticky-table-thead");
    }

    componentWillUnmount(): void {
        const uiSegment = document.querySelector(".ui.segment")!;
        uiSegment.classList.remove("sticky-table-segment");

        const body = document.querySelector("body")!;
        body.classList.remove("sticky-table-body");

        const table = document.querySelector(".ui.celled.table")!;
        table.classList.remove("sticky-table-table");

        const tableHead = document.querySelector(".ui.celled.table > thead")!;
        tableHead.classList.remove("sticky-table-thead");
    }
    
    dateControlChange(data: IPreparedDateControlData) {
        this.fetchData(data);
    }

    fetchData(data: IPreparedDateControlData) {
        ApiRequest.get<IOverviewData[]>(`/Report/UsersOverviewTotal?${data.queryString}`).then(r => {
            this.setState({data: r.data, dateControlData: data});
        });
    }

    render() {

        const headerCells = [makeHeaderCell("email", "Email"), ...this.state.dateControlData.dateHeaders, makeHeaderCell("total", "Total")];

         //TODO: Clean this up
        const data = this.state.data.map(d => applyToFilteredKeys(d, (f, k) => k != "email", (a, k) => transformMinutesToHoursText(a)));

        const table = <div className="sticky-table-div">{makeExpressTable(headerCells, data)}</div>;
        return this.renderWithDateControl(table);
    }
}

export const OverviewPage = OverviewPageImpl;