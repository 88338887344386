import * as React from "react";
import { RenderElement, DATE_ONLY_FORMAT } from "../Globals";
import { Redirect } from "react-router";
import { IApiResponse, IErrorState } from "../Base/Api";
import { Message, Grid, Segment, Form, Label, Container } from "semantic-ui-react";
import ReactCalendar from "rc-calendar";
import * as moment from "moment";
import { IDateStartEnd } from "../Base/AppTypes";

export type TOnDateChange = (start: moment.Moment, end: moment.Moment) => void;
interface IProps extends Partial<IDateStartEnd> {
    onChange: TOnDateChange;
    singleMode?: boolean;
}

interface IState {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

enum CalenderType {
    StartDate = "startDate",
    EndDate = "endDate"
}

type PropsImpl = IProps;
type StateImpl = IState;
class DateControlImpl extends React.Component<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {
            startDate: DateControl.getDefaultStartDateOrParam(this.props.startDate), 
            endDate: DateControl.getDefaultEndDateOrParam(this.props.endDate)
        };
    }

    componentDidMount() {
        this.props.onChange(this.state.startDate, this.state.endDate);
    }

    onChange = (date: moment.Moment, type: CalenderType) => {
        let startDate: moment.Moment, endDate: moment.Moment;
        if(type == CalenderType.StartDate) {
            startDate = date;
            endDate = this.state.endDate;
        }
        else {
            startDate = this.state.startDate;
            endDate = date;
        }

        if(this.props.singleMode) {
            startDate = date;
            endDate = date;
        }

        this.setState({startDate: startDate, endDate: endDate}, () => {
            this.props.onChange(startDate, endDate);
        })
    };

    render() {
        const calendarLeft = <ReactCalendar className="rc-calendar-left"
            value={this.state.startDate}
            onChange={e => this.onChange(e, CalenderType.StartDate)} />;
        const calendarRight = <ReactCalendar className="rc-calendar-right"
            value={this.state.endDate}
            onChange={e => this.onChange(e, CalenderType.EndDate)} />;

        const startText = this.state.startDate.format(DATE_ONLY_FORMAT);
        const endText = this.state.endDate.format(DATE_ONLY_FORMAT);

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>{calendarLeft}</Grid.Column>
                    <Grid.Column width={8}>
                        <Container text={true} textAlign={"center"}>{startText} - {endText}</Container> 
                    </Grid.Column>
                    <Grid.Column width={4}>{calendarRight}</Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    static getDefaultStartDateOrParam(startDate?: moment.Moment) {
        return startDate !== undefined ? startDate : DateControl.getDefaultStartDate();
    }

    static getDefaultEndDateOrParam(endDate?: moment.Moment) {
        return endDate !== undefined ? endDate : DateControl.getDefaultEndDate();
    }

    static getDefaultStartDate() { 
        return moment().startOf("week");
    }

    static getDefaultEndDate() {
        return moment().endOf("week");
    }
}

export const DateControl = DateControlImpl;