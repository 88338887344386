import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Grid, Table, Button, Form, Segment, InputOnChangeData, Icon, Modal, Header} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import {IProject, IProjectWithFavorite} from "../../Network/ResponseTypes";
import {IDataProps} from "../../Base/Semantic";
import {BasePage} from "../../Components/BasePage";
import {makeDataCellDateOnlyText, makeExpressTable, makeHeaderCell} from "../../Utils/TableUtils";

enum ViewType {
    View = "view",
    Add = "add",
}

enum ActionType {
    ChangeViewToAdd = "changeViewToAdd",
    AddNew = "addNew",
    ToggleFavorite = "toggleFavorite",
    Delete = "delete"
}

enum FormNames {
    Name = "name",
}

interface IProps {

}



interface IState {
    action: ViewType;
    name: string;

    projectList: IProjectWithFavorite[];
    modalVisible: boolean;
    deletionId: number;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class ManageProjectsPageImpl extends BasePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {action: ViewType.View, [FormNames.Name]: "",
            projectList: [], modalVisible: false, deletionId: 0};
    }

    componentDidMount() {
        ApiRequest.get<IProjectWithFavorite[]>("/Projects")
            .then(r => this.setState({projectList: r.data}));
    }

    projectModal() {
        const closeModal = (positive: boolean) => {
            const nextState = {modalVisible: false, deletionId: 0};
            if(!positive) {
                this.setState(nextState);
                return;
            }

            const id = this.state.deletionId;
            if(id <= 0) {
                this.setState(nextState);
                return;
            }

            ApiRequest.delete(`/Projects/${id}`).then(r => {
                const projectList = this.state.projectList.filter(p => p.id != id);
                this.setState({projectList: projectList});
            });

            this.setState(nextState);
        };

        return (
            <Modal
                open={this.state.modalVisible}
                onClose={() => closeModal(false)}
                basic={true}
                size="small">
                <Header icon="trash"/>
                <Modal.Content>
                    <h3>Do you want to continue deletion</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                    <Button color="green" onClick={() => closeModal(true)}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    onClick = (event: React.SyntheticEvent<HTMLButtonElement>, data: IDataProps) => {
        const name = data.name;

        let nextState: any = this.state;
        if(name == ActionType.AddNew) {
            ApiRequest.post("/Projects", {name: this.state.name}).then(() => {
                this.setState({action: ViewType.View});
            });
        }
        else if(name == ActionType.Delete) {
            nextState = {modalVisible: true, deletionId: data.payload};
        }
        else {
            nextState = {action: ViewType.Add};
        }

        this.setState(nextState);
    };

    contentOnStateNew() {
        const onChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
            const name = event.currentTarget.name as FormNames.Name;

            const nextState = {[name]: data.value};
            this.setState(nextState);

        };
        const nameInput = <Form.Input value={this.state.name} label={"Project name"}
                                      fluid={true} name={FormNames.Name} onChange={onChange}/>;
        const formData = (
            <>
                {nameInput}
                <Button fluid={true} name={ActionType.AddNew} primary={true}
                        icon="plus" onClick={this.onClick} content={"Add"} />
            </>
        );
        return this.renderFormGrid(formData);
    }

    contentOnStateView() {
        const headers = [
            makeHeaderCell("id", "Id"),
            makeHeaderCell("name", "Name"),
            makeHeaderCell("createdAt", "Created at"),
            makeHeaderCell("actionDelete", "Delete"),
            makeHeaderCell("gitLabUrl", "GitLab url")
        ];

        const table = makeExpressTable(headers, this.state.projectList, (headerCell, dataCell) => {
            makeDataCellDateOnlyText(headerCell, dataCell);
            if(headerCell.referenceKey == "actionDelete") {
                dataCell.jsxRef = (
                    <Button onClick={this.onClick} payload={dataCell.meta.id}
                            name={ActionType.Delete} basic={true} icon={true}>
                        <Icon name="trash"/>
                    </Button>
                );
            }

            if(dataCell.referenceKey == "gitLabUrl") {
                dataCell.jsxRef = <a href={dataCell.meta.url}>{dataCell.meta.name}</a>;
            }

            return dataCell;
        });
        return (
            <>
                <Segment>
                    <Button icon="plus" name={ActionType.ChangeViewToAdd}
                            fluid={true} content="Add new" primary={true} onClick={this.onClick}/>
                </Segment>
                {table}
            </>
        );
    }

    render() {
        let viewToShow = this.contentOnStateView();
        if(this.state.action == ViewType.Add) {
            viewToShow = this.contentOnStateNew();
        }

        return (
            <>
                {viewToShow}
                {this.projectModal()}
            </>
        );
    }
}

export const ManageProjectsPage = ManageProjectsPageImpl;