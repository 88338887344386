import axios from "axios";
import {RequestType} from "../Base/Api";

export interface IApiProxy {
    hasProxyFor(route: string, type?: RequestType): any;
}

export class ApiProxy implements IApiProxy {
    constructor() {

    }

    get(url: string) {
        return this._requestHandlerProxy(RequestType.Get, url)
    }

    post(url: string) {
        return this._requestHandlerProxy(RequestType.Post, url)
    }

    put(url: string) {
        return this._requestHandlerProxy(RequestType.Put, url)
    }

    patch(url: string) {
        return this._requestHandlerProxy(RequestType.Patch, url)
    }

    delete(url: string) {
        return this._requestHandlerProxy(RequestType.Delete, url)
    }

    _requestHandlerProxy(requestType: RequestType, url: string, data?: any) {
        const typeNameTransform =  requestType[0].toUpperCase() + requestType.substr(1);
        const method = "_proxyFor" + typeNameTransform;

        return (this as any)[method].apply(this, [url, data]);
    }

    _proxyForGet(url: string) {
        let resultData = {};

        if(url == "/Dashboard") {
            //console.log("got proxied bitch");

            const topPlayers = [
                    {name: "Zachary", win: 10},
                    {name: "Krum", win: 55},
                    {name: "Vladislav", win: 32},
                    {name: "Svetoslav", win: 11},
                ];
            resultData = {topPlayers: topPlayers.concat(topPlayers).concat(topPlayers)}
        }
        else {
            return undefined;
        }

        const nextResult = this._makeResult(resultData);
        return new Promise(res => {
            setTimeout(() => {
                res(nextResult);
            }, 1000);

        });
    }

    hasProxyFor(route: string, type?: RequestType): any {
        if(type === undefined) {
            type = RequestType.Get;
        }

        return this.proxyRoutes[type].indexOf(route) != -1;
    }

    proxyRoutes: any = {
        [RequestType.Get]: ["_/Dashboard"],
        [RequestType.Post]: [],
        [RequestType.Put]: [],
        [RequestType.Patch]: [],
        [RequestType.Delete]: []
    };

    _makeResult(data: any) {
        const meta = {
            status: 200
        };
        const res = Object.assign({}, meta, {data: data});
        return res;
    }
}