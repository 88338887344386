import * as React from "react";
import { Segment, Button } from "semantic-ui-react";
import { RenderElement } from "../Globals";
import { IDataProps } from "../Base/Semantic";


enum ActionTypes {
    Undo = "undo"
}

interface IStateMap {
    [key: string]: string; //key: text
}

interface IBackStack {
    pop: (extraData?: any) => void;
    push: (state: string, extraData?: any) => void;
}

export interface ITransitionSwitcher extends IBackStack {
    transitionControlls: (backOnly?: boolean) => RenderElement;
}

declare const __DEV__: boolean;
export function createFragmentTransition(initialState: string, stateMap: IStateMap, thisArg: React.Component) {
    const stateBackstack: string[] = [];
    let currentState = initialState;

    const transitionToState = function(this: any, state: string, extraData: any = {}) {

        this.setState({viewState: state, ...extraData});
        
    }.bind(thisArg);

    const push = (state: string, extraData?: any) => {
            if(state == currentState) {
                return;
            }
            stateBackstack.push(currentState);
            currentState = state;
            transitionToState(state, extraData);
    };

    const pop = (extraData?: any) => {
        if(stateBackstack.length <= 0) {
            //transitionToState(initialState);
            return;
        }

        const prevState = stateBackstack.pop()!;
        currentState = prevState;
        transitionToState(prevState, extraData);
    };
    

    const returnObj: Partial<ITransitionSwitcher> & IBackStack = {
        push, pop
    };

   
    const onClick = function(this: any, _: any, props: IDataProps) {
        const nextState = props.payload;
        push(nextState);

    }.bind(thisArg);

    const stateButtons: RenderElement[] = [];
    for(let key in stateMap) {
        const value = stateMap[key];

        stateButtons.push(
            <Button payload={key} onClick={onClick} >
                {value}
            </Button>
        )
    }

    returnObj.transitionControlls = (backOnly: boolean = false) => {
        const length = stateBackstack.length;
        
        const onBackPress = (_: any, props: IDataProps) => {
            pop();
        };
        const backButton = <Button icon={"arrow left"} onClick={onBackPress} />;
        return (
            <Segment>
                {length > 0 && backButton} 
                {!backOnly && stateButtons}
            </Segment>
        );
    }

    return returnObj as ITransitionSwitcher;
}