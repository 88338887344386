import {SessionState} from "http2";

export class StorageHelper {
    static readonly AUTH_KEY_NAME = "auth";
    static readonly AUTH_KEY_VALUE = "hasKey";

    static readonly IS_ADMIN_KEY_NAME = "isAdmin";
    static readonly IS_ADMIN_KEY_NAME_VALUE = "yes";

    static readonly EMAIL_KEY_NAME = "email";
    static readonly EMAIL_KEY_VALUE = "anonymous@example.com";

    static hasAuthKey() {
        const storedValue = localStorage.getItem(StorageHelper.AUTH_KEY_NAME)
        return storedValue !== undefined && storedValue === StorageHelper.AUTH_KEY_VALUE;
    }

    static getAuthKey() {
        return localStorage.getItem(StorageHelper.AUTH_KEY_NAME);
    }

    static initializeAuthKey() {
        localStorage.setItem(StorageHelper.AUTH_KEY_NAME, StorageHelper.AUTH_KEY_VALUE);
    }

    static setAuthKey(value: string) {
        localStorage.setItem(StorageHelper.AUTH_KEY_NAME, value);
    }

    static clearAuthKey() {
        localStorage.removeItem(StorageHelper.AUTH_KEY_NAME);
    }

    static initializeIsAdmin() {
        localStorage.setItem(StorageHelper.IS_ADMIN_KEY_NAME, StorageHelper.IS_ADMIN_KEY_NAME_VALUE);
    }

    static isAdmin() {
		const storedValue = localStorage.getItem(StorageHelper.IS_ADMIN_KEY_NAME);
        return storedValue !== undefined && storedValue === StorageHelper.IS_ADMIN_KEY_NAME_VALUE;
    }

    static clearIsAdmin() {
        localStorage.removeItem(StorageHelper.IS_ADMIN_KEY_NAME);
    }

    static setEmail(email: string = StorageHelper.EMAIL_KEY_VALUE) {
        localStorage.setItem(StorageHelper.EMAIL_KEY_NAME, email);
    }

    static getEmail() {
        return localStorage.getItem(StorageHelper.EMAIL_KEY_NAME);
    }

    static clearEmail() {
        localStorage.removeItem(StorageHelper.EMAIL_KEY_NAME);
    }

    static clearAll() {
        this.clearAuthKey();
        this.clearEmail();
        this.clearIsAdmin();
    }
}