import * as moment from "moment";
import { unwrapKeyValueFromObject } from "../Utils/Helpers";

export interface IDateStartEnd {
    startDate: moment.Moment
    endDate: moment.Moment;
}

export enum MetaDataTypes {
    Ghost = "ghost",
    GhostPrefix = "ghost_"
};

export interface IStringKeyObject<V> {
    [key: string]: V;
}

export interface IKeyValuePair<K, V> {
    key: K;
    value: V
}

export interface IStringValuePair<V> extends IKeyValuePair<string, V> {

}

export function getGhostsFromObject<T>(objects: IStringKeyObject<T>) {
    const ghostObjects: IStringValuePair<any>[] = [];
    for(let key in objects) {
        if(key.includes(MetaDataTypes.GhostPrefix)) {
            ghostObjects.push({
                key: key,
                value: objects[key]
            });
        }
    }

    return ghostObjects;
}

export function mapGhostObject(obj: any) {
    return unwrapKeyValueFromObject(obj).map(v => ({...v, id: 0}));
}

export function isGhost(key: string) {
    return key.includes(MetaDataTypes.GhostPrefix);
}