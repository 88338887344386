import * as React from "react";
import ReactCalendar from "rc-calendar";
import * as moment from "moment";
import {BasePage} from "../../Components/BasePage";
import {Button, Header, Table} from "semantic-ui-react";
import {DATE_ONLY_FORMAT, RenderElement} from "../../Globals";
import {ApiRequest} from "../../Network/ApiRequest";
import {IDataProps} from "../../Base/Semantic";
import {makeExpressTable, makeExpressTableWithTotal, makeHeaderCell} from "../../Utils/TableUtils";
import {transformMinutesToHours, transformMinutesToHoursText, transformMinutesToText} from "../../Utils/DateTimeUtils";
import { BaseReportTimePage, IDateControlDataContainer, IPreparedDateControlData } from "../../Components/BaseReportTimePage";
import { IKeyValuePair, IStringValuePair, IStringKeyObject } from "../../Base/AppTypes";
import { IProject } from "../../Network/ResponseTypes";
import { Link } from "react-router-dom";

enum ActionTypes
{
    ChangeStart = "changeStart",
    ChangeEnd = "changeEnd",

    SwapToUserHoursPerProject = "swapToUserHoursPerProject",
    SwapToProjectHoursPerUser = "SwapToProjectHoursPerUser"
}

enum ViewType
{
    UserHoursPerProject = "userHoursPerProject",
    ProjectHoursPerUser = "projectHoursPerUser"
}

interface IKeyToHours {
    [key: string]: number;
}

/**
 * email: {projectName: hoursWorked} OR
 * projectName: {email: hoursWorked}
 */
interface IResponseResult {
    map: IStringKeyObject<IKeyToHours>
    projects: IProject[];
}

interface IProps {

}

interface IState extends IDateControlDataContainer {
    responseData: IResponseResult;
    viewType: ViewType;
}

type PropsImpl = IProps;
type StateImpl = IState;

const TABLE_CELL_WIDTH = 8;
class WorkPerUnitPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        const start = moment().startOf("month");
        const end = moment().endOf("month");

        this.state = {dateControlData: this.getDefaultDateControlState(), responseData: {map: {}, projects: []},
        viewType: ViewType.UserHoursPerProject};
    }

    dateControlChange(data: IPreparedDateControlData) {
        this.fetchData(data);
    }

    fetchData(data: IPreparedDateControlData) {

        const urlType = this.state.viewType == ViewType.UserHoursPerProject ? "AllProjectTimesForEveryUser" :
            "AllUserTimesForEveryProject";
        ApiRequest.get<IResponseResult>(`/Report/${urlType}?${data.queryString}`).then(r => {
                this.setState({ responseData: r.data, dateControlData: data});
            });
    }

    onClick = (event: React.SyntheticEvent<HTMLButtonElement>, data: IDataProps) => {
        const nextState = {...this.state};
        if(data.name == ActionTypes.SwapToProjectHoursPerUser) {
            nextState.viewType = ViewType.ProjectHoursPerUser;
        }
        else {
            nextState.viewType = ViewType.UserHoursPerProject;
        }

        this.setState(nextState, () => this.fetchData(this.state.dateControlData));
    };
   
    render() {

        const swapButtonProps = this.state.viewType == ViewType.UserHoursPerProject ?
            {name: ActionTypes.SwapToProjectHoursPerUser, content: "Swap to project view"} :
            {name: ActionTypes.SwapToUserHoursPerProject, content: "Swap to user view"};
        const header = (
            <Header textAlign={"center"}>
                <Button {...swapButtonProps} onClick={this.onClick} />
            </Header>
        );

        const controlGrid = this.renderSimpleGrid(null, header, null);
        const mapOfTables: RenderElement[] = [];

        const headerKey = this.state.viewType == ViewType.UserHoursPerProject ? "Project" :
            "User";

        const headerList = [
            makeHeaderCell(headerKey, headerKey),
            makeHeaderCell("hours", "Hours")
        ];

        for(let outerKey in this.state.responseData.map)
        {
            const mappedList: any[] = [];
            const value = this.state.responseData.map[outerKey];
            for(let innerKey in value) {
                const innerValue = value[innerKey];
                mappedList.push({[headerKey]: innerKey, "hours": innerValue});
            }
            
            let header; //shitty js 
            if(this.state.viewType != ViewType.UserHoursPerProject)
            {
                const findProjectId = this.state.responseData.projects.find(p => p.name == outerKey) || {id: 0};
                const anchor = <Link to={`report-overview/id/${findProjectId.id}`}>{outerKey}</Link>
                header = <Header textAlign={"center"}>{anchor}</Header>;
            }
            else
            {
                header = <Header textAlign={"center"}>{outerKey}</Header>;
            }

            
            const table = makeExpressTableWithTotal(headerList, mappedList,
                (h, d) => {
                if(h.referenceKey == "hours") {
                    d.value = transformMinutesToHoursText(Number(d.value));
                }

                return d;
            }, [headerKey]);
            const result = (
                <React.Fragment key={outerKey}>
                    {header}
                    {table}
                </React.Fragment>
            );

            mapOfTables.push(result);
        }

        return this.renderWithDateControl(
            <>
                {controlGrid}
                {mapOfTables}
            </>
        )
    }
}

export const WorkPerUnitPage = WorkPerUnitPageImpl;