import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Grid, Form, Button, Segment, Dropdown, Label, Checkbox, Divider, ButtonProps} from "semantic-ui-react";
import {getKeyIfIdZero, IProject, IProjectWithFavorite, IReportFragment} from "../../Network/ResponseTypes";
import {ApiRequest} from "../../Network/ApiRequest";
import {IKeyGenerator, KeyGenerator} from "../../Utils/Generators";
import {IDataProps} from "../../Base/Semantic";

enum FormNames {
    Project = "project",
    HoursWorked = "hoursWorked",
    Text = "text"
}

enum ActionNames {
    Add = "add",
    Delete = "delete",
    Save = "save",
    ShowAllProjects = "showAllProjects"
}

interface IRouteMatchParams {
    id: number;
}

interface IDto {
    reportFragmentList: IReportFragment[];
    projectList: IProjectWithFavorite[];
}

interface FormData {
    projectId: number;
    hoursWorked: string;
    text: string;
}

interface IProps {

}

interface IState extends IDto {
    reportFragmentListForDeletion: IReportFragment[];
    showAllProjects: boolean;
}


type PropsImpl = IProps & RouteComponentProps<IRouteMatchParams>
type StateImpl = IState;
class ManageReportsPageImpl extends React.Component<PropsImpl, StateImpl> {

    idOfReport: number;
    keyGenerator: IKeyGenerator<string>;
    constructor(props: PropsImpl) {
        super(props);

        this.state = {reportFragmentList: [], projectList: [], reportFragmentListForDeletion: [],
            showAllProjects: false};
        this.keyGenerator = new KeyGenerator();
        this.idOfReport = this.props.match.params.id;
    }

    componentDidMount() {

        ApiRequest.get<IDto>(`/ReportFragments/${this.idOfReport}/Relationships/Projects`).then(r =>
        {
            const reportFragmentList = r.data.reportFragmentList;
            if(reportFragmentList.length == 0) {
                reportFragmentList.push(this.createEmptyReportFragment());
            }
            this.setState({reportFragmentList, projectList: r.data.projectList});
        });
    }

    createEmptyReportFragment = () => {
        const reportFragment: IReportFragment = {
            key: this.keyGenerator.next(),
            id: 0,
            text: "",
            hoursWorked: 8,
            project: {
                id: 0,
                name: "",
                createdAt: "",
            }
        };

        return reportFragment;
    };

    onClick = (event: React.SyntheticEvent<HTMLButtonElement|HTMLInputElement>, data: IDataProps) => {
        const name = data.name;

        if(name == ActionNames.Add) {
            const reportFragment = this.createEmptyReportFragment();
            const nextFragmentList = this.state.reportFragmentList.map(r => r);
            nextFragmentList.push(reportFragment);

            this.setState({reportFragmentList: nextFragmentList});
        }
        else if(name == ActionNames.Delete) {
            const payload = data.payload as IReportFragment;
            const nextFragmentList = this.state.reportFragmentList.filter(rF => rF.id != payload.id);
            const nextFragmentListForDeletion = this.state.reportFragmentListForDeletion.map(r => r);
            nextFragmentListForDeletion.push(payload);

            this.setState({reportFragmentList: nextFragmentList,
                reportFragmentListForDeletion: nextFragmentListForDeletion});
        }
        else {
            const route = `/ReportFragments/${this.idOfReport}`;

            const requestList = [];
            if(this.state.reportFragmentListForDeletion.length > 0){
                requestList.push(ApiRequest.delete(route, this.state.reportFragmentListForDeletion));
            }

            if(this.state.reportFragmentList.length > 0) {
                requestList.push(ApiRequest.post(route, this.state.reportFragmentList));
            }

            ApiRequest.all(requestList).then(r => {
                this.props.history.push("/report/dashboard");
            });
        }
    };

    onChange = (event: React.SyntheticEvent<HTMLInputElement|HTMLTextAreaElement|HTMLElement>, data: IDataProps) => {
        const name = data.name;
        const propsId = data.id;

        const value = data.value;
        const nextArray = this.state.reportFragmentList.map(reportFragment => {
            const fragmentKey = getKeyIfIdZero(reportFragment);
            if(propsId != fragmentKey) {
               return reportFragment;
            }

            if(name == FormNames.Text) {
                reportFragment.text = value;
            }
            else if(name == FormNames.HoursWorked) {
                reportFragment.hoursWorked = value;
            }
            else {
                reportFragment.project.id = value;
            }

            return reportFragment;
        });

        this.setState({reportFragmentList: nextArray});
    };

    buildReportFragmentJsx(reportFragment: IReportFragment) {
        const hasFavorites = this.state.projectList.find(p => p.isFavorite);
        let listForOptions = this.state.projectList;
        const selectedProjectId = reportFragment.project.id;
        if(!this.state.showAllProjects && hasFavorites)
        {
            listForOptions = this.state.projectList.filter(p => p.isFavorite || p.id ==
                selectedProjectId);
        }

        const options = listForOptions.map(project => {
            return {key: project.id, value: project.id, text: project.name};
        });

        const id = getKeyIfIdZero(reportFragment);
        const dropdown = <Dropdown fluid={true} selection={true} id={id}
                                   name={FormNames.Project} onChange={this.onChange}
                               placeholder="Select project" options={options}
        value={selectedProjectId}/>;

        return (
            <Segment>
                <Button name={ActionNames.Delete} onClick={this.onClick} payload={reportFragment} floated="right" content="Delete" icon="trash" />
                <br />
                <br />
                <Divider />
                <Form>
                    <Form.Field><label>Projects</label>{dropdown}</Form.Field>
                    <Form.Input id={id}
                                name={FormNames.HoursWorked} onChange={this.onChange}
                                label="Hours worked" value={reportFragment.hoursWorked}/>
                    <Form.TextArea name={FormNames.Text}
                                   id={id}
                                   onChange={this.onChange}
                               label="Text" value={reportFragment.text}/>
                </Form>
            </Segment>
        );
    }


    render() {
        const addNewFragment = <Button onClick={this.onClick} name={ActionNames.Add}
                                       primary={true} content={"Add new"} icon={"plus"} />;
        const saveAll = <Button onClick={this.onClick} name={ActionNames.Save}
                                primary={true} floated={"right"} content={"Save"} icon={"save"} />;
        const showAllProjects = <Checkbox label={"Show all projects"} onClick={() => {
            this.setState({showAllProjects: !this.state.showAllProjects})}}
            name={ActionNames.ShowAllProjects}/>;
        const segment = (
            <Segment>
                {addNewFragment}
                {showAllProjects}
                {saveAll}
            </Segment>
        );

        const reportFragmentList = this.state.reportFragmentList.map(reportFragment => {
           const id = getKeyIfIdZero(reportFragment);

           const isDeleted = this.state.reportFragmentListForDeletion.find(rF => rF.id == id);
           if(isDeleted !== undefined)
           {
               return null;
           }

           const reportFragmentJsx = this.buildReportFragmentJsx(reportFragment);
           return (
               <Grid.Row key={id}>
                   <Grid.Column>
                       {reportFragmentJsx}
                   </Grid.Column>
               </Grid.Row>
           )
        });

        return (
            <>
                <Grid centered={true} columns={2}>
                    <Grid.Row key={"Actions"}>
                        <Grid.Column>
                            {segment}
                        </Grid.Column>
                    </Grid.Row>
                    {reportFragmentList}
                </Grid>
            </>
        );
    }
}

export const ManageReportsPage = ManageReportsPageImpl;