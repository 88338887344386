import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {IDataProps} from "../../Base/Semantic";
import {Form, Segment, Button} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import {RouteComponentProps} from "react-router";

enum FormNames {
    Email = "email"
}

interface IProps {

}

interface IState {
    [FormNames.Email]: string;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class PasswordResetRequestImpl extends BasePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {[FormNames.Email]: ""}
    }

    onChange = (e: React.SyntheticEvent<HTMLInputElement>, props: IDataProps) => {
        const name = props.name;
        if(name == null) {
            return;
        }

        this.setState({[name]: props.value} as any);
    };

    onClick = (e: React.SyntheticEvent<HTMLButtonElement>, props: IDataProps) => {
        const name = props.name;

        ApiRequest.post("/Authentication/RequestPasswordReset/" + this.state[FormNames.Email]).then(r => {
            this.props.history.push("/login");
        }).catch(r => this.updateErrorState(r));
    };

    render() {
        const formData = (
            <Segment>
                <Form.Input fluid={true}
                            icon="mail"
                            iconPosition="left"
                            placeholder="E-mail address"
                            onChange={this.onChange}
                            name={FormNames.Email}
                            value={this.state[FormNames.Email]}/>
                <Button onClick={this.onClick} color="teal" fluid={true} size="large">
                    Request reset
                </Button>
            </Segment>
        );
        return this.renderFormGrid(formData, this.renderError());
    }
}

export const PasswordResetRequest = PasswordResetRequestImpl;