import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {IDataProps} from "../../Base/Semantic";
import {Form, Segment, Button} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import {RouteComponentProps} from "react-router";

enum FormNames {
    Password = "password",
    PasswordConfirm = "passwordConfirm"
}

interface IProps {

}

interface IState {
    [FormNames.Password]: string;
    [FormNames.PasswordConfirm]: string;
}



type PropsImpl = IProps & RouteComponentProps<{requestToken: string}>;
type StateImpl = IState;
class PasswordResetImpl extends BasePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {[FormNames.Password]: "", [FormNames.PasswordConfirm]: ""};

        //console.log(this.props);
    }

    onChange = (e: React.SyntheticEvent<HTMLInputElement>, props: IDataProps) => {
        const name = props.name;
        if(name == null) {
            return;
        }

        this.setState({[name]: props.value} as any);
    };

    onClick = (e: React.SyntheticEvent<HTMLButtonElement>, props: IDataProps) => {
        const name = props.name;

        const requestBody = {
            password: this.state[FormNames.Password],
            passwordConfirm: this.state[FormNames.PasswordConfirm]
        };
        ApiRequest.post("/authentication/FinalizePasswordReset/" + this.props.match.params.requestToken,
            requestBody).then(r => {
            this.props.history.push("/login");
        }).catch(r => this.updateErrorState(r));
    };

    render() {
        const formData = (
            <Segment>
                <Form.Input fluid={true}
                            icon="lock"
                            iconPosition="left"
                            placeholder="Password"
                            type="password"
                            onChange={this.onChange}
                            name={FormNames.Password}
                            value={this.state[FormNames.Password]}/>
                <Form.Input fluid={true}
                            icon="lock"
                            iconPosition="left"
                            type="password"
                            placeholder="Password confirm"
                            onChange={this.onChange}
                            name={FormNames.PasswordConfirm}
                            value={this.state[FormNames.PasswordConfirm]}/>
                <Button onClick={this.onClick} color="teal" fluid={true} size="large">
                    Reset
                </Button>
            </Segment>
        );
        return this.renderFormGrid(formData, this.renderError());
    }
}

export const PasswordReset = PasswordResetImpl;