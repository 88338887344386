import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {
    IDateAndMinutes, IIssueUserMinutes, IProject, IProjectOverviewReport,
    IProjectWithFavorite
} from "../../Network/ResponseTypes";
import * as moment from "moment";
import {Table, Segment, Button, Label, Header} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import * as qs from "querystring";
import {DATE_ONLY_FORMAT} from "../../Globals";
import {
    getDateOnlyFromDateTimeString, getDayFromDateTimeString,
    transformMinutesToHours, transformMinutesToHoursText, transformMinutesToText
} from "../../Utils/DateTimeUtils";
import {RouteComponentProps} from "react-router";
import {IRouteWithIdPrefix} from "../../Base/RouteTypes";
import {IDataProps} from "../../Base/Semantic";
import {
    makeDataCell,
    makeExpressTable, makeExpressTableWithTotal, makeHeaderCell, makeHeadersForMonth, makeHeadersForWeek,
    mapBodyByHeader, TMakeCellCallback, makeExpressTableImplicitTotal
} from "../../Utils/TableUtils";
import {applyToEachKey, mergeKeysWithCallback, unwrapKeysFromArray} from "../../Utils/Helpers";
import {TimeControl, TTimeControlActionTypes} from "../../Components/TimeControl";
import {start} from "repl";
import { BaseReportTimePage, IDateControlDataContainer, IPreparedDateControlData } from "../../Components/BaseReportTimePage";

enum ActionType {
    ChangeToOverallView = "changeToOverallView",
    ChangeToUsersByIssueView = "changeToUsersByIssueView",
    ChangeToIssuesByUser = "changeToIssuesByUserView"
}

enum ViewType {
    OverallView = "overallView",
    UsersByIssueView = "usersByIssueView",
    IssuesByUserView = "issuesByUserView"
}

interface IProps {

}

interface IState extends IDateControlDataContainer {
    projectOverview: IProjectOverviewReport | null;
    projectList: IProject[];
    timeShiftValue: number;

    viewType:  ViewType;

    isMonthView: boolean;
}

type PropsImpl = IProps & RouteComponentProps<IRouteWithIdPrefix>
type StateImpl = IState;

const FIXED_VALUE_AMOUNT = 0;

class ReportOverviewPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    dateValueArray: number[];

    constructor(props: PropsImpl) {
        super(props);

        this.state = {dateControlData: this.getDefaultDateControlState(), projectOverview: null, timeShiftValue: 0, projectList: [],
            viewType: ViewType.OverallView, isMonthView: false};
        this.dateValueArray = [];
    }

    componentDidMount() {
        const projectId = this.props.match.params.id;
        if(projectId === undefined) {
            this.fetchData(this.state.dateControlData);
        }
    }

    dateControlChange(data: IPreparedDateControlData) {
        this.fetchData(data);
    }

    getUrl(data: IPreparedDateControlData, isExport = false) {
        const projectId = this.props.match.params.id;

        if(projectId === undefined) {
            ApiRequest.get<IProjectWithFavorite[]>("/Projects")
                .then(r => this.setState({projectList: r.data}));
            return;
        }

        let url = `/Report/ProjectOverview/id/${projectId}?${data.queryString}`;
        if(isExport) {
            url = ApiRequest.__getApiPath() + `/Report/ExportProjectOverview/id/${projectId}?${data.queryString}`;
        }

        return url;
    }

    fetchData(data: IPreparedDateControlData) {
        const url = this.getUrl(data);
        if(url === undefined) {
            return;
        }
        ApiRequest.get<IProjectOverviewReport>(url).then(r => {
            this.setState({projectOverview: r.data, dateControlData: data});
        });
    }

    mergeWithSum = (dst: any, src: any) => {
        mergeKeysWithCallback(dst, src, (dst, src) => dst + src);
    };

    applyWithHourTransform = (obj: any) => {
        applyToEachKey(obj, v => transformMinutesToHoursText(v));
    };

    makeHeaders() {
        return [makeHeaderCell("meta", "User/Issue/Date"), ...this.state.dateControlData.dateHeaders, makeHeaderCell("total", "Total")];
    }

    buildProjectList = () => {
        const headers = [
            makeHeaderCell("id", "Id"),
            makeHeaderCell("name", "Name"),
            makeHeaderCell("url", "Url"),
            makeHeaderCell("action", "Action")
        ];

        const onClick = (e: React.SyntheticEvent<HTMLButtonElement>, props: IDataProps) => {
            const id = props.payload;

            this.props.history.push(`./report-overview/id/${id}`);
        };

        const table = makeExpressTable(headers, this.state.projectList, (headerCell,
                                                                         bodyCell) => {
            if(headerCell.referenceKey == "action") {
                const id = bodyCell.meta !== undefined ? bodyCell.meta.id : 0;
                bodyCell.jsxRef = <Button content={"View"} onClick={onClick}
                                          payload={id}/>;
            } else if(headerCell.referenceKey == "url") {
                bodyCell.jsxRef = <a href={bodyCell.meta.url}>{bodyCell.meta.url}</a>;
            }
            
            return bodyCell;
        });

        return table;
    };

    buildOverallView() {
        const header = this.makeHeaders();

        let data: any[] = [];
        const overviewData = this.state.projectOverview;
        if(overviewData == null) {
            return null;
        }

        overviewData.users.forEach(u => {
            const dataEntity: any = {};
            let total = 0;
            u.spentMinutesPerDay.forEach(d => {
                const dateNoTime = getDateOnlyFromDateTimeString(d.date);
                this.mergeWithSum(dataEntity,{[dateNoTime]: d.minutes});
                total += d.minutes;
            });

            this.applyWithHourTransform(dataEntity);

            mergeKeysWithCallback(dataEntity, {meta: u.user.name, total: transformMinutesToHoursText(total)});
            data.push(dataEntity);
        });

        const table = (
            <>
                {makeExpressTableImplicitTotal(header, data)}
            </>
        );

        return table;
    }

    

    buildUsersByIssue() {
        const header = this.makeHeaders();

        let data: any[] = [];
        const overviewData = this.state.projectOverview;
        if(overviewData == null) {
            return null;
        }

        overviewData.issues.forEach(i => {
            const name = i.issue.title;
            const dateData = {};

            let total = 0;
            let isEntireRowEmpty = true;
            i.spentMinutesPerDay.forEach(d => {
                const minutes = d.minutes;
                if(minutes != 0) {
                    isEntireRowEmpty = false;
                }
                const dateNoTime = getDateOnlyFromDateTimeString(d.date);
                total += minutes;
                this.mergeWithSum(dateData,{[dateNoTime]: minutes});
            });

            this.applyWithHourTransform(dateData);
            mergeKeysWithCallback(dateData, {meta: name, isName: false,
                total: transformMinutesToHoursText(total)});

            if(isEntireRowEmpty) {
                return;
            }
            data.push(dateData);

            i.users.forEach(u => {
                const userData = {};
                const name = u.user.name;
                let total = 0;

                u.spentMinutesPerDay.forEach(d => {
                    const minutes = d.minutes;
                    if(minutes != 0) {
                        isEntireRowEmpty = false;
                    }
                    const dateNoTime = getDateOnlyFromDateTimeString(d.date);
                    total += minutes;
                    this.mergeWithSum(userData, {[dateNoTime]: minutes});
                });

                this.applyWithHourTransform(userData);
                mergeKeysWithCallback(userData, {meta: name, isName: true,
                    total: transformMinutesToHoursText(total)});

                data.push(userData);
            });
        });

        const callback: TMakeCellCallback = (headerCell, dataCell) => {
            if(dataCell.meta.isName == false) {
                dataCell.style = {backgroundColor: "#CCC"};
            }

            return dataCell;
        };

        const table = (
            <>
                {makeExpressTableImplicitTotal(header, data, callback)}
            </>
        );

        return table;
    }

    buildIssuesByUser() {
        const header = this.makeHeaders();
        let data: any[] = [];
        const overviewData = this.state.projectOverview;
        if(overviewData == null) {
            return null;
        }

        overviewData.users.forEach(r => {
            const user = r.user;
            const meta = user.name;

            const applyObject: any = {};
            let total = 0;

            r.spentMinutesPerDay.forEach(s => {
                const dateNoTime = getDateOnlyFromDateTimeString(s.date);
                total += s.minutes;
                this.mergeWithSum(applyObject, {[dateNoTime]: s.minutes});
            });

            this.applyWithHourTransform(applyObject);
            mergeKeysWithCallback(applyObject, {meta, total: transformMinutesToHoursText(total), isName: true});
            data.push(applyObject);

            r.issues.forEach(i => {
                const issue = i.issue;
                const meta = issue.title;

                const innerApplyObject: any = {};
                let total = 0;
                let isEntireRowEmpty = true;

                i.spentMinutesPerDay.forEach(s => {
                    const minutes = s.minutes;
                    if(minutes != 0) {
                        isEntireRowEmpty = false;
                    }
                    total += minutes;
                    const dateNoTime = getDateOnlyFromDateTimeString(s.date);
                    this.mergeWithSum(innerApplyObject, {[dateNoTime]: s.minutes});
                });

                if(isEntireRowEmpty) {
                    return;
                }

                this.applyWithHourTransform(innerApplyObject);
                mergeKeysWithCallback(innerApplyObject, {meta, total: transformMinutesToHoursText(total), isName: false});

                data.push(innerApplyObject);
            })
        });

        const callback: TMakeCellCallback = (headerCell, dataCell) => {
            if(dataCell.meta.isName == false) {
                dataCell.style = {backgroundColor: "#CCC"};
            }

            return dataCell;
        };

        const table = (
            <>
                {
                    makeExpressTableImplicitTotal(header, data, callback, true)
                }
            </>
        );

        return table;
    }

    onClick = (e: React.SyntheticEvent<HTMLButtonElement>, props: IDataProps) => {
        const name = props.name;
        if(name === undefined) {
            return;
        }


        const nextState: any = {};
        if(name == ActionType.ChangeToUsersByIssueView) {
            nextState.viewType = ViewType.UsersByIssueView;
        }
        else if(name == ActionType.ChangeToOverallView) {
            nextState.viewType = ViewType.OverallView;
        }
        else if(name == ActionType.ChangeToIssuesByUser) {
            nextState.viewType = ViewType.IssuesByUserView;
        }


        if(nextState.viewType !== undefined) {
            this.setState(nextState);
        }
    };

    render() {
        const projectId = this.props.match.params.id;
        if(projectId === undefined) {
            return this.buildProjectList();
        }

        const makeMap = (text: string, name: ActionType, viewType: ViewType) => {
            const isDisabled = viewType == this.state.viewType;
            return <Button key={name} content={text} name={name} onClick={this.onClick} disabled={isDisabled}/>
        };

        const mapOfButtons = [
            makeMap("Show overall", ActionType.ChangeToOverallView, ViewType.OverallView),
            makeMap("Show users by issues", ActionType.ChangeToUsersByIssueView, ViewType.UsersByIssueView),
            makeMap("Show issues by user", ActionType.ChangeToIssuesByUser, ViewType.IssuesByUserView),
            <Button key="download_csv" content="Download default csv"
                    onClick={() => window.open(this.getUrl(this.state.dateControlData, true), "_blank")}/>
        ];

        let renderResult = null;
        if(this.state.viewType == ViewType.OverallView) {
            renderResult = this.buildOverallView();
        }
        else if(this.state.viewType == ViewType.UsersByIssueView) {
            renderResult = this.buildUsersByIssue();
        }
        else if(this.state.viewType == ViewType.IssuesByUserView) {
            renderResult = this.buildIssuesByUser();
        }

        let projectName = "";
        const overview = this.state.projectOverview;
        if(overview != null) {
            projectName = overview.project.name;
        }

        return this.renderWithDateControl(
            <>
                {mapOfButtons}
                <div>
                    <Header textAlign="center">
                        {projectName}
                        <br />
                        {this.state.dateControlData.dateInfo}
                    </Header>
                    {renderResult}
                </div>
            </>
        )
    }
}

export const ReportOverviewPage = ReportOverviewPageImpl;