import * as React from "react";
import {ExtendedTable, IBodyContainer, IHeaderCell} from "../../Components/ExtendedTable";
import {ApiRequest} from "../../Network/ApiRequest";
import * as moment from "moment";
import {DATE_ONLY_FORMAT, DATE_ONLY_NO_DAY, RenderElement} from "../../Globals";
import {mapArrayToKeys, padNumberWithZero, StringifyDateQuery, unwrapKeysFromArray, unwrapKeyValueFromObject, getIdForValue, numberToColor, simpleFastHash} from "../../Utils/Helpers";
import {makeHeaderCell, mapBodyByHeader, prepareHeaderCellsFromDateStringArray, makeExpressTable} from "../../Utils/TableUtils";
import {BasePage} from "../../Components/BasePage";
import {DateTable} from "../../Components/DateTable";
import {getDayFromDateOnlyString, makeStringDateRange, appendMidnightToDate, compareByDateOnly} from "../../Utils/DateTimeUtils";
import {AdvancedDateTable} from "../../Components/AdvancedDateTable";
import { DateControl, TOnDateChange } from "../../Components/DateControl";
import { BaseReportTimePage, IPreparedDateControlData, IDateControlDataContainer } from "../../Components/BaseReportTimePage";
import { IUser, IProject, IScheduleItem } from "../../Network/ResponseTypes";
import { IDataProps, TOnGenericEventInterface, TMouseEvents } from "../../Base/Semantic";
import { Dropdown, Segment, Label, Form, Button } from "semantic-ui-react";
import { RibbonField } from "../../Components/RibbonField";
import { ITransitionSwitcher, createFragmentTransition } from "../../Components/FragmentTransition";
import { GhostSequenceGenerator } from "../../Utils/Generators";
import { getGhostsFromObject, IStringKeyObject, isGhost } from "../../Base/AppTypes";
import { groupBy, filterSplit, mapToObj, removeKey } from "../../Utils/ArrayUtils";

enum ViewStates {
    Overview = "overview",
    Manage = "manage"
}

enum FormNames {
    User = "user",
    Project = "project",
    Minutes = "hours",

    AddGhost = "addGhost",
    ManageForDate = "manage",
    Delete = "delete",
    Submit = "submit"
}

interface IDto {
    projects: IProject[];
    users: IUser[];

    schedules: IScheduleItem[];
}

interface IProps {

}

interface IState extends IDateControlDataContainer {
    data?: IDto;
    viewState: ViewStates;
    selectedDateTime?: string;
    userScheduleItems: {
        [key: string]: Partial<IScheduleItem>
    };

    deletionKeys: string[];
}


interface IUserGridItem {
    user: IUser;
    
    schedules: IScheduleItem[];
}

interface IHeaderInfo {
    date: string;
    colStart: number;
    colEnd: number;
}

const DEFAULT_DATE_ROW = 1;
const DEFAULT_META_COL = 1;

const MAX_HOURS_PER_DAY = 8;
const MAX_MINUTES_PER_DAY = MAX_HOURS_PER_DAY * 60;
const MINUTES_PER_HOUR = 60;

type TProjectColor = IStringKeyObject<string>;

type PropsImpl = IProps;
type StateImpl = IState;
class TestPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    
    projectColorMap: TProjectColor = {};
    fragmentSwitcher: ITransitionSwitcher;
    constructor(props: PropsImpl) {
        super(props);
        this.state = {
            dateControlData: this.getDefaultDateControlState(), 
            viewState: ViewStates.Overview, 

            userScheduleItems: {
                
            },
            deletionKeys: [],
        };
        

        this.fragmentSwitcher = createFragmentTransition(ViewStates.Overview, {
            [ViewStates.Overview]: "Overview",
            [ViewStates.Manage]: "Manage"
        }, this);
    }

    componentDidMount() {
        this.fetchData(this.state.dateControlData);
    }

    dateControlChange(dateControlData: IPreparedDateControlData) {
       this.fetchData(dateControlData);
    }

    fetchData(dateControlData: IPreparedDateControlData) {
        ApiRequest.get<IDto>("/Schedules").then(r => {
            const scheduleMap = r.data.schedules.reduce((acc, val) => {
                acc[val.id] = val;

                return acc;
            }, {} as any);

            this.projectColorMap = r.data.projects.reduce((acc, val) => {
                acc[val.name] = numberToColor(simpleFastHash(val.name));
                return acc;
            }, {} as any);
            console.log(this.projectColorMap);
            this.setState({ data: r.data, dateControlData, userScheduleItems: scheduleMap});
        });
    }
    
    onClick: TMouseEvents = (_, props) => {
        const {name, payload} = props;
        const {data} = this.state;

        switch(name) {
            case FormNames.AddGhost: {
                const userScheduleItems = { ...this.state.userScheduleItems };
                userScheduleItems[GhostSequenceGenerator.next()] = {minutes: MAX_MINUTES_PER_DAY};
                this.setState({ userScheduleItems });
                break;
            }
            case FormNames.ManageForDate: {
                const date = payload;
                const userScheduleItems = data!.schedules.reduce((acc, val) => {
                    if(!compareByDateOnly(val.atTime, date)) {
                        return acc;
                    }
                    acc[String(val.id)] = val

                    return acc;
                }, {} as any);
                this.fragmentSwitcher.push(ViewStates.Manage, {
                    selectedDateTime: appendMidnightToDate(date),
                    userScheduleItems,
                    deletionKeys: []
                });

                break;
            }
            case FormNames.Delete: {
                if(isGhost(payload)) {
                    this.setState({userScheduleItems: removeKey(this.state.userScheduleItems, payload)});
                    return;
                }
                const ref = this.state.deletionKeys;
                ref.push(payload);
                this.setState({ deletionKeys: ref });
            }
            case FormNames.Submit: {
                const items = unwrapKeyValueFromObject<Partial<IScheduleItem>>(this.state.userScheduleItems, (val, k) => {
                    const v = {...val};
                    v.atTime = this.state.selectedDateTime;
                    v.projectId = v.project.id;
                    v.project = undefined;

                    v.userId = v.user.id;
                    v.user = undefined;

                    return v;
                });

                const { deletionKeys } = this.state;
                const fs = filterSplit<Partial<IScheduleItem>>(items, v => !deletionKeys.includes(String(v.id)));

                const dto = {
                    deletionIds: fs.unmached.map(f => f.id),
                    upserts: fs.matched
                };

                console.log(dto, fs);

                ApiRequest.patch("/Schedules", dto).then(r => {
                    this.fetchData(this.state.dateControlData);
                    this.fragmentSwitcher.pop();
                });
            }
        }
    };

    onChange = (_: any, props: IDataProps) => {
        const {data} = this.state;
        const {value, name, payload} = props;
        const id = payload;
        const userScheduleItems = { ...this.state.userScheduleItems };
        if(name == FormNames.Project) {
            const project = data!.projects.find(p => p.id == value);
            userScheduleItems[id].project = project;
        } else if(name == FormNames.User) {
            const user = data!.users.find(u => u.id == value);
            userScheduleItems[id].user = user;
        } else {
            userScheduleItems[id].minutes = value;
        }

        this.setState({ userScheduleItems })
    };
    
    renderTimeSegments(headerArray: string[], scheduleItem: IScheduleItem) {
        const dateIndex = headerArray.findIndex(d => compareByDateOnly(d, scheduleItem.atTime));
        const gridColumnStart = dateIndex + 1;

        const totalDaysSegment = Math.round(scheduleItem.minutes / MAX_MINUTES_PER_DAY);
        const totalSingleDaySegment = Math.min(scheduleItem.minutes, MAX_MINUTES_PER_DAY) / MINUTES_PER_HOUR;

        const gridColumnEnd = gridColumnStart + totalDaysSegment;
        const gridRowStart = 1;
        const gridRowEnd = gridRowStart + totalSingleDaySegment;

        const projectName = scheduleItem.project.name;
        const backgroundColor = this.projectColorMap[projectName];

        const timeStyle = { gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd, backgroundColor};

        return (
            <div className="item-time" style={timeStyle} key={scheduleItem.id}>
                {projectName}
            </div>
        );
    }

    renderTimeSegment(dateMap: IStringKeyObject<IHeaderInfo>, userGrid: IUserGridItem) {
        const renderItems: RenderElement = [];
        const { user, schedules } = userGrid;

        const groupByDate = groupBy(schedules, s => s.atTime);

        const revDate = this.state.dateControlData.dateRangeString.map(v => appendMidnightToDate(v));
        for(let i = revDate.length - 1; i >= 1; i--) {
            const dateHigh = revDate[i];
            const dateLow = revDate[i - 1];

            const schedulesHigh = groupByDate[dateHigh] || [];
            if(schedulesHigh.length != 1) {
                continue;
            }

            const schedulesLow = groupByDate[dateLow] || [];
            if(schedulesLow.length != 1) {
                continue;
            }
            
            const scheduleHigh = schedulesHigh[0];
            const scheduleLow = schedulesLow[0];

            if(scheduleHigh.project.id != scheduleLow.project.id) {
                continue;
            }

            if(scheduleHigh.minutes < MAX_MINUTES_PER_DAY) {
                continue;
            }

            if(scheduleLow.minutes < MAX_MINUTES_PER_DAY) {
                continue;
            }

            

            console.log(scheduleHigh, scheduleLow);
            scheduleLow.minutes += scheduleHigh.minutes;
            schedulesHigh.pop();
        }

        
        for(let key in groupByDate) {
            const schedules = groupByDate[key];
            const headerInfo = dateMap[key];

            let nextFreeRowSlot = 1;
            for(let schedule of schedules) {
                const {project, atTime} = schedule;
               
                const spanTimeForDay = schedule.minutes / MINUTES_PER_HOUR;
                const spentDays = schedule.minutes / MAX_MINUTES_PER_DAY;
                const backgroundColor = this.projectColorMap[project.name];

                const gridColumnEnd = spentDays > 1 ? `span ${Math.floor(spentDays)}` : headerInfo.colEnd

                const style = {
                    gridRowStart: nextFreeRowSlot,
                    gridRowEnd: `span ${spanTimeForDay}`,
                    gridColumnStart: headerInfo.colStart, gridColumnEnd,
                    backgroundColor
                };

                nextFreeRowSlot += spanTimeForDay;

                renderItems.push(
                    <div className="item-time" style={style} key={schedule.id}>
                        {project.name}
                    </div>
                );
            }
        }


        return renderItems;
    }

    renderOverview(data: IDto, dateControlData: IPreparedDateControlData) {
        const { dateRangeString } = dateControlData;
        const width = window.innerWidth;
        const headerArray = ["meta", ...dateRangeString];
        const rangeItemCount = headerArray.length;
        const pxSize = width / dateRangeString.length + "px";

        const templateCount = Math.min(rangeItemCount, 31);
        const overridenStyleMagic = {
            gridTemplateColumns: `repeat(${templateCount}, ${pxSize})`
        };

        const dateHeaderJsx = headerArray.map(v => {
            return <div key={v} onClick={(e) => this.onClick(e, {name: FormNames.ManageForDate, payload: v})} className="item-date">{v}</div>
        });
        
        const dateHeaderInfo = headerArray.map((v, i) => {
            const date = v, colStart = i, colEnd = colStart + 1;
            return {colStart, colEnd, date} as IHeaderInfo
        });
        
        const dateTimeMapped = mapToObj(dateHeaderInfo, d => appendMidnightToDate(d.date));

        const timesliceGridStyle = {gridTemplateColumns: `repeat(${templateCount}, ${pxSize})`};
        const groupedSchedules = groupBy(data.schedules, s => s.user.id);
        const events: IUserGridItem[] = data.users.map((u, i) => {
            const scheduleForUser = groupedSchedules[u.id];
            let rowStart = i + DEFAULT_META_COL;
            
            const userGrid = {
                user: u,
                schedules: scheduleForUser == undefined ? [] : scheduleForUser,
                rowStart
            };

            return userGrid;
        });

        const scheduleRenderJsx = events.map(ug => {
            const u = ug.user;

            const userJsx = <div key={u.id} className="item-meta">{u.name}</div>;
            const timeSegmejtJsx = this.renderTimeSegment(dateTimeMapped, ug);

            return (
                <React.Fragment key={u.id}>
                    {userJsx}
                    <div className="item-grid-data" style={timesliceGridStyle}>
                        {timeSegmejtJsx}
                    </div>
                </React.Fragment>
            );
        });

        // const events = data.users.map(u => {
        //     const schedulesForUser = groupedSchedules[u.id];

        //     let spanStyle: React.CSSProperties = {};
        //     let scheduleRender: RenderElement = null;
        //     if(schedulesForUser !== undefined && schedulesForUser.length > 0) {
        //         spanStyle = {};//{ gridRowStart: renderGridIndex };
        //         renderGridIndex += 8;

        //         //const grpByDate = groupBy(schedulesForUser, "")
        //         scheduleRender = schedulesForUser.map(s => this.renderTimeSegments(dateRangeString, s));
        //     }

        //     const userJsx = <div key={u.id} className="item-meta" style={spanStyle}>{u.name}</div>;
        //     return (
        //         <React.Fragment key={u.id}>
        //             {userJsx}
        //             <div className="item-grid-data" style={timesliceGridStyle}>
        //                 {scheduleRender}
        //             </div>
        //         </React.Fragment>
        //     );
        // });

        return (
            <div className="timeline">
                <div className="grid-outer" style={overridenStyleMagic}>
                    {dateHeaderJsx}
                    {scheduleRenderJsx}
                </div>
            </div>
        );
    }

    renderSingleManage(data: IDto, key: string, schedule: Partial<IScheduleItem>) {
        const projectList = data.projects.map(p => {
            return { text: p.name, value: p.id};
        });
        const userList = data.users.map(u => {
            return { text: u.name, value: u.id};
        });
        const hourList = new Array(MAX_HOURS_PER_DAY).fill(0).map((v, i) => {
            return { text: i + 1, value: (i + 1) * 60 }
        });

        const projectDefault = getIdForValue(schedule.project);
        const userDefault = getIdForValue(schedule.user);
        const hoursDefault = {value: schedule.minutes};
        
        const sharedProps = {selection: true, fluid: true, search: true};

        const projectDropdown = <Dropdown payload={key} name={FormNames.Project} onChange={this.onChange} {...projectDefault} {...sharedProps} placeholder="Select a project" options={projectList} />;
        const userDropdown = <Dropdown payload={key} name={FormNames.User} onChange={this.onChange} {...userDefault} {...sharedProps} placeholder="Select a user" options={userList} />;
        const timeDropdown = <Dropdown payload={key} name={FormNames.Minutes} onChange={this.onChange} {...hoursDefault} {...sharedProps} search={false} placeholder="Select hours" options={hourList} />;
        const form = (
            <Form>
                <Segment>
                    Manage for date: {this.state.selectedDateTime}
                    <br />
                    <br />
                    <Button name={FormNames.Delete} onClick={this.onClick} payload={key} icon="delete" />
                </Segment>
                <RibbonField title="Project">
                    {projectDropdown}
                </RibbonField>
                <RibbonField title="User">
                    {userDropdown}
                </RibbonField>
                <RibbonField title="Hours">
                    {timeDropdown}
                </RibbonField>
            </Form>
        );

        return (
            <div className="form-box" key={key}>
                {form}
            </div>
        );
    }

    renderManage(data: IDto) {
        const userSchedules = data.schedules.filter(s => s.atTime == this.state.selectedDateTime);
        const {deletionKeys} = this.state;
        //console.log(userSchedules);
        const userForms = userSchedules.filter(s => !deletionKeys.includes(String(s.id)))
            .map(v => this.renderSingleManage(data, String(v.id), v));

        const ghostForms = getGhostsFromObject<Partial<IScheduleItem>>(this.state.userScheduleItems)
            .filter(v => !deletionKeys.includes(v.key))
            .map(v => this.renderSingleManage(data, v.key, v.value));

        const forms = [...userForms, ...ghostForms];

        return this.renderSimpleGrid(null, (
            <>
                {forms}
                <Button name={FormNames.Submit} onClick={this.onClick}>Submit</Button>
                <Button name={FormNames.AddGhost} onClick={this.onClick}>Add new template</Button>
            </>
        ));
    }

    render() {
        const {data, viewState, dateControlData} = this.state;
        
        let renderItem = null;
        if(data == null) {
            return renderItem;
        }
      
        if(viewState == ViewStates.Overview) {
            renderItem = this.renderOverview(data, dateControlData);
        } else {
            renderItem = this.renderManage(data);
        }

        const renderWrapper = (
            <>
                {this.fragmentSwitcher.transitionControlls(true)}
                {renderItem}
            </>
        );

        return this.renderWithDateControl(renderWrapper);
    }
}

export const TestPage = TestPageImpl;