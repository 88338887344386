import {MenuItemProps} from "semantic-ui-react";
import * as React from "react";

type PositionType = "left" | "right";


export function makeMenuItem(key: string, name: string, icon?: string, position?: PositionType,
                             extras?: MenuItemProps) {
    if(extras === undefined) {
        extras = {};
    }

    if(icon !== undefined) {
        extras["icon"] = icon;
    }

    if(position !== undefined) {
        extras["position"] = position;
    }

    extras["content"] = name;

    return {key, name: key, ...extras};
}

export interface IMenuItemProps extends MenuItemProps {
    key: string;
    path: string;
}

export interface IDataProps {
    [key: string]: any;
    id?: string;
    name?: string;
    value?: any;
    payload?: any;
    meta?: any;
}

export interface IDataPropsEx<T> {
    [key: string]: any;
    id?: T;
    name?: string;
    value?: any;
    payload?: any;
}

export interface IGenericKeyedInterface<T> {
    [key: string]: T
}

export type TOptionalStringOrNumber = string | number | undefined;
export type TOnGenericEventInterface<T> = (event: React.SyntheticEvent<T>, props: IDataProps) => void;
export type TOnEventInterface<T, P> = (event: React.SyntheticEvent<T>, props: P) => void;

type TMouseEventTypes = HTMLButtonElement | HTMLAnchorElement | HTMLDivElement;

export type TMouseEvents = (event: React.MouseEvent<TMouseEventTypes>, props :IDataProps) => void;