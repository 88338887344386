import * as React from "react";
import {RenderElement} from "../Globals";
import {Redirect} from "react-router";
import {IApiResponse, IErrorState} from "../Base/Api";
import {Message, Grid, Segment, Form} from "semantic-ui-react";

interface IProps {

}

interface IState {

}



type PropsImpl = IProps;
type StateImpl = IState;
class BasePageImpl<P, S> extends React.Component<P, S> {
    centerForm(element: RenderElement) {
        const internal = <div className="internal-container-form">{element}</div>;
        const external = <div className="external-container-form">{internal}</div>;

        return external;
    }

    renderWithAuth(element: RenderElement) {
        if(localStorage.getItem("auth") == null) {
            return <Redirect to={"/login"}/>;
        }

        return element;
    }

    renderError = (): RenderElement => {
        const errorState = this.state as IErrorState;
        if(errorState.errors === undefined || errorState.errors.length <= 0) {
            return null;
        }

        const error = errorState.errors[0];


        const header = <Message.Header>#{error.id} - {error.title}</Message.Header>;
        const message = (
            <Message error={true}>
                {header}
                <p>
                    {error.details}
                </p>
            </Message>
        );

        return message;
    };

    updateErrorState = (response :IApiResponse<any>) =>  {
        const errors = response.errors;
        this.setState({errors: errors} as any);
    };

    renderSimpleGrid(left: RenderElement = null, center: RenderElement = null, right: RenderElement = null) {
        const gridColumn = (
            <>
                <Grid.Column floated={"left"}>{left}</Grid.Column>
                <Grid.Column>{center}</Grid.Column>
                <Grid.Column floated={"right"}>{right}</Grid.Column>
            </>
        );

        return (
            <Grid columns={3}>
                <Grid.Row>{gridColumn}</Grid.Row>
            </Grid>
        )
    }

    renderFormGrid(formData: RenderElement, preForm?: RenderElement, postForm?: RenderElement) {
        if(preForm === undefined) {
            preForm = null;
        }

        if(postForm === undefined) {
            postForm = null;
        }

        return this.centerForm(
        <Grid textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 512 }}>
                {preForm}
                <Form size="large">
                    {formData}
                </Form>
                {postForm}
            </Grid.Column>
        </Grid>
        );
    }
}

export const BasePage = BasePageImpl;