/** 
 * TODO: Documentation
*/
import {ExtendedTable, IDataCell, IHeaderCell, IMappableBodyData, IRowContainer} from "../Components/ExtendedTable";
import {IKeyGenerator, KeyGenerator} from "./Generators";
import * as React from "react";
import * as moment from "moment";
import {Table} from "semantic-ui-react";
import {DateTable} from "../Components/DateTable";
import {DATE_ONLY_NO_DAY} from "../Globals";
import {boolToString, boolToText, padNumberWithZero} from "./Helpers";
import StartOf = moment.unitOfTime.StartOf;
import {DurationInputArg1, DurationInputArg2} from "moment";
import {getDateOnlyFromDateTimeString, getDayFromDateOnlyString, textToMinutes, transformMinutesToHoursText} from "./DateTimeUtils";


export function makeHeaderCell(referenceKey: string, text: string, style?: React.CSSProperties): IHeaderCell {
    return {
        referenceKey,
        text,
        style
    };
}

export function makeDataCell(referenceKey: string, value: string, style?: React.CSSProperties,
                             meta?: any): IDataCell {
    return {
        referenceKey,
        value,
        style,
        meta
    };
}

//The callback can mutate the variable
export type TMakeCellCallback = (header: IHeaderCell, dataCell: IDataCell) => IDataCell;
export function mapRowsByHeader(headers: IHeaderCell[], data: IMappableBodyData[],
                                makeCellCallback?: TMakeCellCallback) {
    const rows: IRowContainer[] = [];
    const keyGenerator: IKeyGenerator<string> = new KeyGenerator();

    for(let dataIndex in data) {
        const dataValue = data[dataIndex];

        const row: IDataCell[] = [];
        for(let headerKey in headers) {
            const headerValue = headers[headerKey];
            const headerReferenceKey = headerValue.referenceKey;

            let valueToUse = "";
            if(dataValue.hasOwnProperty(headerReferenceKey)) {
                valueToUse = dataValue[headerReferenceKey];
            }

            let result = makeDataCell(headerReferenceKey, valueToUse, undefined, dataValue);
            if(makeCellCallback !== undefined) {
                result = makeCellCallback(headerValue, result);
            }

            row.push(result);
        }

        rows.push({
            dataCellList: row
        });
    }

    return rows;
}

export function mapBodyByHeader(headers: IHeaderCell[], data: IMappableBodyData[], makeCellCallback?: TMakeCellCallback) {
    const rowList = mapRowsByHeader(headers, data, makeCellCallback);
    return {
        rowList
    };
}

export function makeExpressTable(headers: IHeaderCell[], data: IMappableBodyData[],
                                 makeCellCallback?: TMakeCellCallback) {
    const mappedData = mapBodyByHeader(headers, data, makeCellCallback);
    return (
        <ExtendedTable headerItemList={headers} bodyContainer={mappedData}/>
    );
}

export function makeExpressTableImplicitTotal(headers: IHeaderCell[], data: IMappableBodyData[], 
    makeCellCallback?: TMakeCellCallback, divByTwo: boolean = false) {
    const implicitItemLength = 2;
    const headersCount = headers.length;
    const fillerCount = headersCount - implicitItemLength;

    /*
    const localData = headers.reduce((acc, val) => {
        const matchNum = val.referenceKey.match(/\d+/);
        if(matchNum == null) {
            return acc;
        }

        return acc;
    });*/
    let grandTotal = 0;
    for(let i = 0; i < data.length; i++) {
        const totalLocal = data[i].total;

        const transformTime = textToMinutes(totalLocal);

        grandTotal += transformTime;
    }

    if(divByTwo) {
        grandTotal = grandTotal / 2;
    }

    const totalData = { meta: "Grand total", total: transformMinutesToHoursText(grandTotal) } as any;
    for(let i = 1; i < fillerCount; i++) {
        const headerKey = headers[i].referenceKey;
        totalData[headerKey] = "";
    }


    const mappedData = mapBodyByHeader(headers, data, makeCellCallback);
    const mappedTotalData = mapBodyByHeader(headers, [totalData], (h, d) => {
        d.style = { backgroundColor: "#70bd70"};

        return d;
    });

    return (
        <ExtendedTable headerItemList={headers} bodyContainerList={[mappedData, mappedTotalData]} />
    );
}

export function makeExpressTableWithTotal(headers: IHeaderCell[], data: IMappableBodyData[],
                                          makeCellCallback?: TMakeCellCallback,
                                          ignoreCellRef?: string[]) {

    let isIgnoreCellRefEmpty  = false;
    if(ignoreCellRef === undefined) {
        ignoreCellRef = [];
        isIgnoreCellRefEmpty = true;
    }

    const rexToMatch = /\d+/;
    const totalHeaders: IHeaderCell[] = headers.map((h, i) => {
        if(i == 0) {
            return makeHeaderCell("total", "Total");
        }

        if(isIgnoreCellRefEmpty) {
            const refKey = h.referenceKey;
            const res = h.referenceKey.search(rexToMatch) != -1;
            if(res) {
                ignoreCellRef!.push(refKey);
            }
        }

        return h;
    });



    const keySums: any = {total: "Total"};
    data.forEach((d, i) => {
        for(let k in d) {
            if(ignoreCellRef!.includes(k)) {
                continue;
            }

            if(!(k in keySums)) {
                keySums[k] = 0;
            }

            keySums[k] += d[k];
        }
    });

    const mappedTotalData = mapBodyByHeader(totalHeaders, [keySums], makeCellCallback);
    const mappedData = mapBodyByHeader(headers, data, makeCellCallback);
    const bodyList = [mappedData, mappedTotalData];
    return (
        <ExtendedTable headerItemList={headers} bodyContainerList={bodyList}/>
    );
}


//Optimized for up to months
export function makeHeadersForTimePeriod(dateStart: string, periodLength: DurationInputArg1 , anchorTo: StartOf,
                                         left?: IHeaderCell[], right?: IHeaderCell[]) {
    const start = moment(dateStart).startOf(anchorTo);
    const end = start.clone().add(periodLength, anchorTo as DurationInputArg2);

    const diff = end.diff(start, "days");

    const noDayStr = start.format(DATE_ONLY_NO_DAY) + "-";
    const startDay = start.date(); // day 01, 02 == date for moment -_-
    const mapDates = new Array(diff).fill(0).map( (_, i) => (
        padNumberWithZero(startDay + i)
    ));

    const mapFullDate = mapDates.map(r => noDayStr + r);

    const result = mapFullDate.map((r, i) => makeHeaderCell(r, mapDates[i]));

    if(left !== undefined) {
        result.unshift(...left);
    }

    if(right !== undefined) {
        result.push(...right);
    }


    return result;
}

export function makeHeadersForWeek(date: string, left?: IHeaderCell[], right?: IHeaderCell[]) {
    return makeHeadersForTimePeriod(date, 1, "week", left, right);
}

export function makeHeadersForMonth(date: string, left?: IHeaderCell[], right?: IHeaderCell[]) {
    return makeHeadersForTimePeriod(date, 1, "month", left, right);
}

export function makeDataCellBoolText(headerCell: IHeaderCell, dataCell: IDataCell,
                                     referenceKeyToMatch: string) {
    if(headerCell.referenceKey != referenceKeyToMatch) {
        return dataCell;
    }

    dataCell.value = boolToString(dataCell.value as any as boolean);
    return dataCell;
}

export function makeDataCellDateOnlyText(headerCell: IHeaderCell, dataCell: IDataCell,
                                         referenceKeysToMatch = ["createdAt", "updatedAt"]) {
    if(!referenceKeysToMatch.includes(headerCell.referenceKey)) {
        return dataCell;
    }

    dataCell.value = getDateOnlyFromDateTimeString(dataCell.value);

    return dataCell;
}

export function prepareHeaderCellsFromDateStringArray(dates: string[]) {
    const ranges = dates.map(v => makeHeaderCell(v, getDayFromDateOnlyString(v).toString()));

    return ranges;
}

