import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {RouteComponentProps, RouterProps} from "react-router";
import {IProjectOverviewReport, IProjectOverviewSimple, IProject, IIssueMinutes, IUser, IPersonalReport} from "../../Network/ResponseTypes";
import {ApiRequest} from "../../Network/ApiRequest";
import {makeExpressTable, makeHeaderCell, mapBodyByHeader, makeDataCell} from "../../Utils/TableUtils";
import {transformMinutesToHoursText, transformMinutesToText, getDateOnlyFromDateTimeString} from "../../Utils/DateTimeUtils";
import { BaseReportTimePage, IDateControlDataContainer, IPreparedDateControlData } from "../../Components/BaseReportTimePage";
import { Header } from "semantic-ui-react";
import { RenderElement } from "../../Globals";
import { mapArrayToKeys, unwrapKeysFromArray} from "../../Utils/Helpers";

interface IProps {

}

interface IMatchProps {
    id: number;
}



interface IState extends IDateControlDataContainer {
    data?: IPersonalReport;
}



type PropsImpl = IProps & RouteComponentProps<IMatchProps>;
type StateImpl = IState;
class PersonalReportPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);
        this.state = { dateControlData: this.getDefaultDateControlState() };
    }

    componentDidMount() {

    }

    dateControlChange(data: IPreparedDateControlData) {
        this.setState({ dateControlData: data }, () => { this.fetchData(data) });
    }

    fetchData(data: IPreparedDateControlData) {
        const {params} = this.props.match;

        ApiRequest.get<IPersonalReport>(`/Report/PersonalReport/id/${params.id}?${data.queryString}`).then(r => {
            this.setState({ data: r.data });
        });
    }

    buildTableData(issueMinutes: IIssueMinutes) {
        return mapArrayToKeys(issueMinutes.spentMinutesPerDay, (v) => {
            return {
                key: getDateOnlyFromDateTimeString(v.date), value: transformMinutesToHoursText(v.minutes)
            }
        });
    }

    render() {
        let result: RenderElement = null;
        const data = this.state.data;
        if(data !== undefined) {
            const emailLeft = data.user.email.split("@")[0];
            const header = (
            <Header>
                <a href={`https://git.siriussoftware.bg/admin/users/${emailLeft}`}>{data.user.name}</a> - {transformMinutesToHoursText(data.totalSpentMinutes)}
            </Header>);

            const headers = [makeHeaderCell("meta", "Issue"), ...this.state.dateControlData.dateHeaders, makeHeaderCell("total", "Total")];
            const projectBody = data.projectIssueMinutes.map(p => {
                const projectHeader = <Header><a href={p.project.url}>{p.project.name}</a> - {transformMinutesToHoursText(p.totalSpentMinutes)}</Header>;

                const tableBody = p.issueMinutes.map(i => {
                    const total = transformMinutesToHoursText(i.totalSpentMinutes);
                    const tableData = {meta: i.issue.title, ...this.buildTableData(i), payload: i.issue, total};
                    
                 
                    return tableData;
                });
                

                const table = makeExpressTable(headers, tableBody, (h, d) => {
                    if(d.referenceKey == "meta") {
                        d.jsxRef = <a href={`https://${d.meta.payload.url}`}>{d.value}</a>;
                    }

                    return d;
                });

                return (
                    <React.Fragment key={p.project.id}>
                        {projectHeader}
                        {table}
                    </React.Fragment>
                );
            });
            result = (
                <>
                    {header}
                    <hr />
                    {projectBody}
                </>
            );
        }
       
        return this.renderWithDateControl(result);
    }
}

export const PersonalReportPage = PersonalReportPageImpl;