import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {ApiRequest} from "../../Network/ApiRequest";
import {IUser} from "../../Network/ResponseTypes";
import {Button, Segment, Form} from "semantic-ui-react"
import {boolToText} from "../../Utils/Helpers";
import {makeDataCellBoolText, makeDataCellDateOnlyText, makeExpressTable, makeHeaderCell} from "../../Utils/TableUtils";
import {IDataProps} from "../../Base/Semantic";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { RouteComponentProps } from "react-router";

enum ViewType {
    UserList = "userList",
    NewUser = "newUser"
}

enum ActionType {
    ChangeViewType = "changeViewType",
    RegisterUser = "registerUser"
}

enum FormNames {
    email = "email",
    name = "name",
    password = "password",
    passwordConfirm = "passwordConfirm"
}

interface IProps {

}

interface IState {
    userList: IUser[];
    viewType: ViewType;

    [FormNames.email]: string;
    [FormNames.name]: string;
    [FormNames.password]: string;
    [FormNames.passwordConfirm]: string;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class ManageUsersPageImpl extends BasePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {userList: [], viewType: ViewType.UserList,
            [FormNames.email]: "", [FormNames.name]: "",
            [FormNames.password]: "", [FormNames.passwordConfirm]: ""};
    }

    componentDidMount() {
        ApiRequest.get<IUser[]>("/Users").then(r => {
            this.setState({userList: r.data});
        });
    }

    renderUserList() {

        const headers = [
            makeHeaderCell("email", "Email"),
            makeHeaderCell("name", "Name"),
            makeHeaderCell("createdAt", "CreatedAt"),
            makeHeaderCell("isDisabled", "Disabled"),
            //makeHeaderCell("actionDisable", "Disable"),
            makeHeaderCell("actionPersonalReport", "View personal report"),
            makeHeaderCell("gitLabUrl", "GitLab url")
        ];

        const table = makeExpressTable(headers, this.state.userList, (headerCell, dataCell) => {
            makeDataCellDateOnlyText(headerCell, dataCell);
            makeDataCellBoolText(headerCell, dataCell, "isDisabled");

            if(dataCell.referenceKey == "actionDisable") {
                const disableActionText = boolToText(dataCell.value as any as boolean,
                    {isTrue: "Enabled", isFalse: "Disable"});
                dataCell.jsxRef = <Button content={disableActionText} />;
            
            }

            if(dataCell.referenceKey == "actionPersonalReport") {
                const viewPersonalReportCallback = () => {
                    this.props.history.push(`personal-report/id/${dataCell.meta.id}`);
                }
                dataCell.jsxRef = <Button content={"View"} onClick={viewPersonalReportCallback}/>
            }

            if(dataCell.referenceKey == "gitLabUrl") {
                const emailLeft = dataCell.meta.email.split("@")[0];
                dataCell.jsxRef = <a href={`https://git.siriussoftware.bg/admin/users/${emailLeft}`}>{emailLeft}</a>;
            }
            
            return dataCell;
        });

        return table;
    }

    renderNewUser() {
        const nameInput = <Form.Input fluid={true} icon="user" iconPosition="left" placeholder="Name"
                                      onChange={this.onChange} name={FormNames.name} value={this.state.name}/>;
        const emailInput = <Form.Input fluid={true} icon="mail" iconPosition="left" placeholder="E-mail address"
                                       onChange={this.onChange} name={FormNames.email} value={this.state.email}/>;

        const passwordInput = <Form.Input fluid={true} icon="lock" iconPosition="left" placeholder="Password"
                                          autoComplete={"password"} type="password" name={FormNames.password} onChange={this.onChange}/>;
        const passwordConfirmInput = <Form.Input fluid={true} icon="lock" iconPosition="left"
                                                 autoComplete={"password"}
                                                 placeholder="Password confirm" type="password"
                                                 name={FormNames.passwordConfirm} onChange={this.onChange}/>;

        const formData = (
            <>
                <Segment>
                    {nameInput}
                    {emailInput}
                    {passwordInput}
                    {passwordConfirmInput}
                </Segment>
                <Button onClick={this.onClick} fluid={true} name={ActionType.RegisterUser} size="large">
                    Register
                </Button>
            </>
        );
        return this.renderFormGrid(formData, this.renderError());
    }


    onChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const name = event.currentTarget.name;
        const value = data.value;
        const nextState = {...this.state, [name]: value};

        this.setState(nextState);
    };

    onClick = (e: React.SyntheticEvent<HTMLButtonElement>, data: IDataProps) => {
        const name = data.name;

        if(name == ActionType.RegisterUser) {
            const move = () => {
                this.setState({viewType: ViewType.UserList});
            };
            ApiRequest.post("/Authentication/Register", this.state)
                .then(res => move())
                .catch(rej => this.updateErrorState(rej))
        }
        else {
            const nextViewType = this.state.viewType == ViewType.UserList ? ViewType.NewUser : ViewType.UserList;

            this.setState({viewType: nextViewType});
        }

    };

    render() {
        let result = null;
        let actionText = "Register a user";
        if(this.state.viewType == ViewType.UserList) {
            result = this.renderUserList();
        }
        else {
            actionText = "User list";
            result = this.renderNewUser();
        }

        return (
            <>
                <Segment>
                    <Button content={actionText} onClick={this.onClick}/>
                </Segment>
                {result}
            </>
        )
    }
}

export const ManageUsersPage = ManageUsersPageImpl;