import * as React from "react";
import {Grid, Form, Segment, Header, Button} from "semantic-ui-react";
import {BasePage} from "../../Components/BasePage";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {IUser} from "../../Network/ResponseTypes";
import {StorageHelper} from "../../Network/StorageHelper";
import {ApiRequest} from "../../Network/ApiRequest";
import {RouteComponentProps} from "react-router";

enum FormNames {
    CurrentPassword = "currentPassword",
    NewPassword = "newPassword",
    NewPasswordConfirm = "newPasswordConfirm",
}

interface IProps {

}

interface IState {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class ProfilePageImpl extends BasePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);

        this.state = {currentPassword: "", newPassword: "", newPasswordConfirm: ""};
    }

    onChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const name = data.name;
        const value = data.value;
        const newState = {...this.state, [name]: value};

        this.setState(newState);
    };

    onClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        ApiRequest.post("/Users/ChangePassword", this.state).then(r => {
            this.props.history.push("/report/Dashboard");
        }).catch(r => this.updateErrorState(r));
    };


    render() {
        const currentPassword =  <Form.Input fluid={true}
                                             icon='lock'
                                             iconPosition='left'
                                             placeholder='Current password'
                                             onChange={this.onChange}
                                             type="password"
                                             name={FormNames.CurrentPassword}
                                             value={this.state.currentPassword}/>;

        const newPassword =  <Form.Input fluid={true}
                                             icon='lock'
                                             iconPosition='left'
                                             placeholder='New password'
                                             type="password"
                                             onChange={this.onChange}
                                             name={FormNames.NewPassword}
                                             value={this.state.newPassword}/>;

        const newPasswordConfirm =  <Form.Input fluid={true}
                                             icon='lock'
                                             iconPosition='left'
                                             placeholder='New password confirm'
                                             type="password"
                                             onChange={this.onChange}
                                             name={FormNames.NewPasswordConfirm}
                                             value={this.state.newPasswordConfirm}/>;
        const formData = (
            <>
                <Segment stacked={true}>
                    {currentPassword}
                    {newPassword}
                    {newPasswordConfirm}

                    <Button onClick={this.onClick}  primary={true} fluid={true}>
                        Change password
                    </Button>
                </Segment>
            </>
        );
        const preForm = (
            <>
                <Header as='h3' color='black' textAlign='center'>
                    Password change form
                </Header>
                {this.renderError()}
            </>
        );
        return this.renderFormGrid(formData, preForm);
    }
}

export const ProfilePage = ProfilePageImpl;