import * as React from "react";
import { BasePage } from "./BasePage";
import * as moment from "moment";
import { IHeaderCell } from "./ExtendedTable";
import { RenderElement, DATE_ONLY_FORMAT } from "../Globals";
import { render } from "react-dom";
import { DateControl } from "./DateControl";
import { makeStringDateRange } from "../Utils/DateTimeUtils";
import { makeHeadersForTimePeriod, prepareHeaderCellsFromDateStringArray } from "../Utils/TableUtils";
import { StringifyDateQuery } from "../Utils/Helpers";
import { IDateStartEnd } from "../Base/AppTypes";

export interface IPreparedDateControlData extends IDateStartEnd {
    queryString: string;
    dateRangeString: string[];
    dateHeaders: IHeaderCell[];
    dateInfo: string;
}

export interface IDateControlDataContainer {
    dateControlData: IPreparedDateControlData;
}

interface IProps {

}

interface IState {

}

type PropsImpl = IProps;
type StateImpl = IState;
class BaseReportTimePageImpl<P, S> extends BasePage<P, S> {
    dateControlChange(dateData: IPreparedDateControlData) {

    }

    private _OnChange = (startDate: moment.Moment, endDate: moment.Moment) => {
        this.dateControlChange(BaseReportTimePageImpl.makeData(startDate, endDate));
    }

    //Dangerously bad
    protected singleMode: boolean = false;
    
    private static makeData(startDate: moment.Moment, endDate: moment.Moment) {
        const dateRangeString = makeStringDateRange(startDate, endDate);
        const dateHeaders = prepareHeaderCellsFromDateStringArray(dateRangeString);
        const queryString = StringifyDateQuery(startDate, endDate);
        const dateInfo = `${startDate.format(DATE_ONLY_FORMAT)} <==> ${endDate.format(DATE_ONLY_FORMAT)}`;

        return {startDate: startDate, endDate: endDate, dateRangeString, dateHeaders, queryString, dateInfo};
    }

    renderWithDateControl(element: RenderElement, initialData?: Partial<IDateStartEnd>) {
        let passStateData = initialData != null ? initialData : {};
        return (
            <>
                <DateControl onChange={this._OnChange} singleMode={this.singleMode} {...passStateData}/>
                {element}
            </>
        );
    }

    getDefaultDateControlState(startDate?: moment.Moment, endDate?: moment.Moment) { 
        return BaseReportTimePage._getDefaultDateControlState(startDate, endDate);
    }

    private static _getDefaultDateControlState(startDate?: moment.Moment, endDate?: moment.Moment) {
        const startDateValue = DateControl.getDefaultStartDateOrParam(startDate);
        const endDateValue = DateControl.getDefaultEndDateOrParam(endDate);
        
        return BaseReportTimePage.makeData(startDateValue, endDateValue);
    }
}

export const BaseReportTimePage = BaseReportTimePageImpl;