import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {BaseAdminPage} from "./BaseReportPage";
import {Button, Grid} from "semantic-ui-react";
import {ApiRequest} from "../../Network/ApiRequest";
import {Bar, BarChart, CartesianGrid, Legend, Line, LineChart, XAxis, YAxis} from "recharts";
import {Calendar, stringOrDate, momentLocalizer} from 'react-big-calendar'
import * as moment from "moment";
import {RouteComponentProps} from "react-router";
import {IProjectOverviewReport, IReport, IReportFragment} from "../../Network/ResponseTypes";
import {start} from "repl";
import {IKeyGenerator, KeyGenerator} from "../../Utils/Generators";
import {DATE_ONLY_FORMAT} from "../../Globals";
import {
    getDateOnlyFromDateTimeString, transformMinutesToHoursText,
    transformMinutesToText
} from "../../Utils/DateTimeUtils";

interface ISlotInfo
{
    start: stringOrDate;
    end: stringOrDate;
    slots: Date[] | string[];
    action: 'select' | 'click' | 'doubleClick';
}

interface IEventData {
    id: number;
    title: string;
    start: stringOrDate;
    end: stringOrDate;
}

interface IOverview {
    name: string;
    issues: [{
        timeLogs: [{
            atTime: string;
            minutes: number;
        }]
    }]
}

interface IProps  {

}

interface IState {
    eventData: IEventData[];
}

moment.locale("en-gb");
const localizer = momentLocalizer(moment);
type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class IndexPageImpl extends BasePage<PropsImpl, StateImpl> {

    keyGenerator: IKeyGenerator<string>;
    constructor(props: PropsImpl) {
        super(props);

        this.state = {eventData: []};
        this.keyGenerator = new KeyGenerator();
    }

    componentDidMount() {
        ApiRequest.get<IOverview[]>(`/Report/ProjectOverviewDashboard`).then(r => {
            const result = r.data;
            const eventList: IEventData[] = [];
			let counter: any = {};

            result.map(p => {
                const dateMap: any = {};
                p.issues.forEach(i => {
                    i.timeLogs.forEach(t => {
                        const dateOnly = getDateOnlyFromDateTimeString(t.atTime);
                        if(dateMap[dateOnly] === undefined) {
                            dateMap[dateOnly] = 0;
                        }

                        dateMap[dateOnly] += t.minutes;
                    });
                });
				
                for(let key in dateMap) {
                    const time = transformMinutesToHoursText(dateMap[key]);
					
					if(!counter[key])
					{
						counter[key] = 0;
					}
					
					let startDate = new Date(key as string);
					let endDate = new Date(key as string);
					
					startDate.setMinutes(30 * counter[key]);
					endDate.setMinutes(30 * (counter[key] + 1));
					
					counter[key]++;
					
                    eventList.push({
                        id: this.keyGenerator.nextNumber(),
                        start: startDate,
                        end: endDate,
                        title: `${p.name} ${time}`
                    });
                }
            });

            this.setState({eventData: eventList});
        });
    };

    onSelectSlot = (slotInfo: ISlotInfo) => {
        console.log(slotInfo);
        if(slotInfo.action != "click") {
            return;
        }
        if(slotInfo.slots.length != 1) {
            return;
        }

        const slotDate = moment(slotInfo.slots[0]);
        const date = slotDate.format(DATE_ONLY_FORMAT);
        //console.log(date);
        //return;
        ApiRequest.post<IReport>("/Reports", {reportAt: date}).then(r => {
            const id = r.data.id;
            if(id == 0) {
                return;
            }

            //this.props.history.push(`./manage-reports/id/${id}`);
        });
    };

    render() {
        const width = window.innerWidth * 0.95;
        const height = window.innerHeight * 0.90;
        const style = {width, height};
        return (
            <Grid centered={true}>
                <Grid.Column style={style}>
                    <Calendar events={this.state.eventData}
								localizer={localizer}
								views={["month","week","day"]}
                             //    onSelectSlot={this.onSelectSlot}
                             //    selectable={true}
                    />
                </Grid.Column>
            </Grid>
        );
    }
}

export const IndexPage = IndexPageImpl;