import * as React from "react";
import { Form, Label } from "semantic-ui-react";

interface IProps {
    title: string;
}

interface IState {

}

type PropsImpl = IProps;
type StateImpl = IState;
class RibbonFieldImpl extends React.Component<PropsImpl, StateImpl> {

    render() {
        return (
            <Form.Field>
                <Label as="a" color="blue" ribbon>
                    {this.props.title}
                </Label>
                {this.props.children}
            </Form.Field>
        )
    }
}


export const RibbonField = RibbonFieldImpl;