import * as moment from "moment"
import { DATE_ONLY_FORMAT } from "../Globals";

export const ISO_DATETIME_DATE_FROM_TIME_SEPARATOR = "T";
export const ISO_DATETIME_DATE_SEPARATOR = "-";

function isDateAndTime(dateOrDateTime: string) {
    return dateOrDateTime.includes(ISO_DATETIME_DATE_FROM_TIME_SEPARATOR);
}

export function appendMidnightToDate(date: string) {
    return date + ISO_DATETIME_DATE_FROM_TIME_SEPARATOR + "00:00:00";
}

export function compareByDateOnly(left: string, right: string) {
    if(isDateAndTime(left)) {
        left = getDateOnlyFromDateTimeString(left);
    }

    if(isDateAndTime(right)) {
        right = getDateOnlyFromDateTimeString(right);
    }

    return left == right;
}

export function getDateOnlyFromDateTimeString(date: string) {
    return date.split(ISO_DATETIME_DATE_FROM_TIME_SEPARATOR)[0];
}

export function getDayFromDateOnlyString(dateOnly: string): number {
    return Number(dateOnly.split(ISO_DATETIME_DATE_SEPARATOR)[2]);
}

export function getDayFromDateTimeString(dateTime: string): number {
    const dateString = getDateOnlyFromDateTimeString(dateTime);
    const day = getDayFromDateOnlyString(dateString);

    return day;
}

export function transformMinutesToHours(minutes: number, fixedPoints = 2) {
    if(minutes == 0) {
        return "";
    }

    return (minutes / 60).toFixed(fixedPoints);
}

export function transformMinutesToHoursText(minutes: number, fixedPoints = 2) {
    if(minutes == 0) {
        return "";
    }

    const hours = Math.trunc(minutes / 60);
    minutes -= hours * 60;

    let outputText = "";
    if(minutes != 0)
    {
        outputText = `${minutes}m` + outputText
    }
    if(hours != 0)
    {
        outputText = `${hours}h ` + outputText;
     }

    return outputText;
}

//Copied from C# (GitLabUtility::MinutesToTime)
export function transformMinutesToText(minutes: number) {
    if(minutes == 0) {
        return "";
    }

    let hours = Math.trunc(minutes / 60);
    minutes -= hours * 60;

    let days = Math.trunc(hours / 8);
    hours -= days * 8;

    let weeks = Math.trunc(days / 5);
    days -= weeks * 5;

    let time = "";

    if(weeks != 0)
    {
        time += weeks + "w";
    }

    if(days != 0)
    {
        if(time != "")
        {
            time += " ";
        }

        time += days + "d";
    }

    if(hours != 0)
    {
        if(time != "")
        {
            time += " ";
        }

        time += hours + "h";
    }

    if(minutes != 0)
    {
        if(time != "")
        {
            time += " ";
        }

        time += minutes + "m";
    }

    return time;
}

export function textToMinutes(time: string)
{
    if(time == "") {
        return 0;
    }

    time = time.trim();

    let minutes = 0;
    const parts = time.split(' ');

    for(let part of parts)
    {
        const value = parseInt(part.substring(0, part.length - 1));

        if(value == null || isNaN(value)) {
            //Empty
        }
        else if(part.endsWith("m")) {
            minutes += value;
        }
        else if(part.endsWith("h")) {
            minutes += value * 60;
        }
        else if(part.endsWith("d")) {
            minutes += value * 8 * 60;
        }
        else if(part.endsWith("w")) {
            minutes += value * 5 * 8 * 60;
        }
        else {
            throw new Error("Incorrect format for GitLab time string: " + time + ".");
        }
    }

    return minutes;
}

//Highly inefficient
export function isWorkDay(date: string) {
    if(date.match(/\d{4}-\d{2}/) == null) {
        return true;
    }
    const weekDay = moment(date).isoWeekday();
    return weekDay >= 1 && weekDay <= 5;
}

export function isDateString(date: string) {
    return date.match(/\d{4}-\d{2}-\d{2}/) !== null;
}

export function makeStringDateRange(start: moment.Moment, end: moment.Moment) {
    const diff = end.diff(start, "days") + 1;

    const str: string[] = [];
    for(let i = 0; i < diff; i++) {
        const nextDate = start.clone().add(i, "days");
        str.push(nextDate.format(DATE_ONLY_FORMAT));
    }

    return str;
}