export interface IIDEntity {
    id: number;
}

type DateAsString = string;

export interface IUser extends IIDEntity {
    email: string;
    name: string;
    isAdmin: boolean;
    createdAt: string;
    isDisabled: boolean;
}

export interface IProject extends IIDEntity {
    name: string;
    createdAt: DateAsString;
    user?: string;
    url?: string;
}

export interface IReport extends IIDEntity {
    reportFragmentList: IReportFragment[];
    submittedFor: DateAsString;
}

export interface IScheduleItem extends IIDEntity {
    user: IUser;
    project: IProject;
    minutes: number;

    atTime: DateAsString;
}

export interface IReportFragment extends IIDEntity {
    key?: string;
    text: string;
    hoursWorked: number;

    project: IProject;
}

export const getKeyIfIdZero = (item: any) => {
    return item.id != 0 ? item.id : item.key;
};

export interface IProjectWithFavorite extends IProject {
    isFavorite: boolean
}


export interface IReportExt {
    fromTime: DateAsString;
    toTime: DateAsString;

    generationTime: DateAsString;
}

export interface IDateAndMinutes {
    date: DateAsString;
    minutes: number;
}

export interface ITimeLog {
    user: IUser;
    atTime: DateAsString;
    minutes: number;
}

export interface IUserMinutes {
    user: IUser;
    spentMinutesPerDay: IDateAndMinutes[];
    totalSpentMinutes: number;
}

export interface IIssue extends IIDEntity {
    title: string;
    url: string;
    estimateMinutes: number;
    spentMinutes: number;
    timeLogs: ITimeLog[];
}

export interface IIssueUserMinutes {
    issue: IIssue;
    users: IUserMinutes[];
    spentMinutesPerDay: IDateAndMinutes[];
    estimateMinutes: number;
    totalSpentMinutes: number;
}

export interface IIssueMinutes {
    issue: IIssue;
    spentMinutesPerDay: IDateAndMinutes[];
    totalSpentMinutes: number;
    estimationBias: number;
}

export interface IProjectOverviewUser {
    issues: IIssueUserMinutes[];
    spentMinutesPerDay: IDateAndMinutes[];
    totalSpendMinutes: number;

    user: IUser;
}

export interface IProjectOverviewReport extends IReportExt {
    project: IProject;

    issues: IIssueUserMinutes[];
    spentMinutesPerDay: IDateAndMinutes[];

    estimateMinutes: number;
    totalSpentMinutes: number;

    users: IProjectOverviewUser[];
}

export interface IProjectOverviewSimple extends IReportExt {
    title: string;
    timeLogs: ITimeLog[];
}

export interface IProjectIssueMinutes {
    project: IProject;
    issueMinutes: IIssueMinutes[];
    totalSpentMinutes: number;
}

export interface IPersonalReport {
    projectIssueMinutes: IProjectIssueMinutes[];
    user: IUser;
    totalSpentMinutes: number;
}