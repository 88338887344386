import { MetaDataTypes } from "../Base/AppTypes";

export interface IKeyGenerator<T> {
    next(): T;
    nextNumber(): number;
}

class KeyGeneratorImpl implements IKeyGenerator<string> {

    counter = 0;
    keyPrefix: string;
    constructor(keyPrefix: string = ""){
        this.keyPrefix = keyPrefix
    }

    next() {
        return this.keyPrefix + this.counter++;
    }

    nextNumber() {
        return this.counter++;
    }
}


export const KeyGenerator = KeyGeneratorImpl;
export const GhostSequenceGenerator = new KeyGenerator(MetaDataTypes.GhostPrefix);
