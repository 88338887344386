import * as React from "react";
import {Redirect, Route, Switch} from "react-router";
import {LoginPage} from "./Page/Index/LoginPage";
import {hot} from "react-hot-loader";
import {IndexPage} from "./Page/Report/IndexPage";
import {ManageUsersPage} from "./Page/Report/ManageUsersPage";
import {BaseAdminPage} from "./Page/Report/BaseReportPage";
import {RegisterPage} from "./Page/Index/RegisterPage";
import {TestPage} from "./Page/Index/TestPage";
import {PasswordResetRequest} from "./Page/Index/PasswordResetRequest";
import {PasswordReset} from "./Page/Index/PasswordReset";

interface IProps {

}

interface IState {

}

type PropsImpl = IProps;
type StateImpl = IState;
class RouterManagerImpl extends React.Component<PropsImpl, StateImpl> {
    render() {
        return (
            <Switch>
                <Route path={"/"} exact={true}  render={() => <Redirect to={"/login"}/>} />
                <Route path={"/login"} component={LoginPage} />
                <Route path={"/password-reset-request"} component={PasswordResetRequest} />
                <Route path={"/password-reset/:requestToken"} component={PasswordReset} />
                <Route path={"/test-page"} component={TestPage} />
                <Route path={BaseAdminPage.rootLocationPath + "/"} component={BaseAdminPage} />
            </Switch>
        )
    }
}




export const RouteManager = hot(module)(RouterManagerImpl);