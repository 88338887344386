import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {RenderElement} from "../../Globals";
import {Menu, MenuItemProps, Grid, Segment, Dropdown, Label, Icon} from "semantic-ui-react";
import {IDataProps, IMenuItemProps, makeMenuItem} from "../../Base/Semantic";
import {Redirect, Route, RouteComponentProps, RouteProps, RouterProps} from "react-router";
import {IndexPage} from "./IndexPage";
import {ManageUsersPage} from "./ManageUsersPage";
import {ManageReportsPage} from "./ManageReportsPage";
import {StorageHelper} from "../../Network/StorageHelper";
import {ManageProjectsPage} from "./ManageProjectsPage";
import {OverviewPage} from "./OverviewPage";
import {ProfilePage} from "./ProfilePage";
import {WorkPerUnitPage} from "./WorkPerUnitPage";
import {ReportOverviewPage} from "./ReportOverviewPage";
import {PersonalReportPage} from "./PersonalReportPage";
import { ChartPage } from "./ChartPage";
import { CompanyOverviewPage } from "./CompanyOverviewPage";
import { ManageSchedulesPage } from "./ManageSchedulesPage";

interface IProps {

}

interface IState {
    menuItemList: MenuItemProps[];
}

interface IRenderMenuProperties {
    isForAdmin?: boolean;
    isInOverflow?: boolean;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class BaseReportPage extends BasePage<PropsImpl, StateImpl> {
    static rootLocationPath = "/report";
    static indexRouteKey = "dashboard";
    static logoutRouteKey = "logout";
    static profileRouteKey = "profile";
    static indexRoutePath = BaseReportPage.rootLocationPath + "/" + BaseReportPage.indexRouteKey;

    static renderMenu: MenuItemProps[] = [
        makeMenuItem(BaseReportPage.indexRouteKey, "Dashboard", "home"),
        makeMenuItem("users", "Users", "user"),
        makeMenuItem("manage-projects", "Manage projects", "terminal"),
        makeMenuItem("overview", "Overview", "table"),
        makeMenuItem("work-per-unit", "Work per unit", "bolt"),
        makeMenuItem("report-overview", "Report overview", "table"),
        makeMenuItem("charts", "Charts", "chart line"),
        makeMenuItem("company-overview", "Company overview", "tasks"),
        makeMenuItem("schedules", "Schedules", "calendar alternate outline"),
        makeMenuItem(BaseReportPage.profileRouteKey, "Profile", "user circle", "right"),
        makeMenuItem(BaseReportPage.logoutRouteKey, "Logout", "log out", "right")
    ];

    static renderMenuProperties: {
        [key: string]: IRenderMenuProperties
    } = {
        "users": {isForAdmin: true},
        "manage-reports": {isForAdmin: true},
        "overview": {isForAdmin: true, isInOverflow: true},
        "work-per-unit": {isForAdmin: true, isInOverflow: true},
        "charts": {isForAdmin: true},
        "company-overview": {isForAdmin: true, isInOverflow: true},
        "report-overview": {isForAdmin: true, isInOverflow: true},
        "schedules": {isForAdmin: true}
    };

    constructor(props: PropsImpl) {
        super(props);

        this.state = this.getDefaultState();
    }

    protected getDefaultState() {
        const routePath = this.extractFromPathName();
        const routePathClean = routePath.substr(1);
        const mapped = BaseReportPage.renderMenu.map(r => {
            const name = r.name || BaseReportPage.indexRouteKey;
            if(routePathClean == name || (routePathClean == "/" && name ==
                    BaseReportPage.indexRouteKey)) {
                r.active = true;
            }
            return r;
        });

        return {menuItemList: mapped};
    }

    private onMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement|HTMLDivElement>,
                               data: IDataProps) => {
        const resultName = data.name;
        if(resultName == BaseReportPage.logoutRouteKey) {
            this.props.history.push("/");
            StorageHelper.clearAll();
        }

        const props = (this.props as any as PropsImpl);
        props.history.push(BaseReportPage.rootLocationPath + "/" + resultName);

        const nextMenuList = this.state.menuItemList.map(m => {
            m.active = (m.name == resultName);

            return m;
        });
        const nextState = {menuItemList: nextMenuList};

        this.setState(nextState);
    };

    render() {
        if(!StorageHelper.hasAuthKey()) {
            return <Redirect to={"/login"}/>;
        }
        const isAdmin = StorageHelper.isAdmin();
        const renderMenu = BaseReportPage.renderMenu.filter(r => {
            const isAdminOnly = BaseReportPage.renderMenuProperties[r.name!] !== undefined;

            //console.log(r.name);
            return !isAdminOnly || (isAdminOnly && isAdmin);

        });

        const dropDownMenuItem: RenderElement[] = [];
        const verticalMenuItem: RenderElement[] = [];
        let indexOfProfile = 0;
        renderMenu.forEach((r, i)=> {
            const checkForOverflow = BaseReportPage.renderMenuProperties[r.name!]
            if(checkForOverflow !== undefined && checkForOverflow.isInOverflow) {
                dropDownMenuItem.push(this.menuItemAsDropdown(r));
                return;
            }

            verticalMenuItem.push(this.menuItemAsVertical(r));
            if(r.name == BaseReportPage.profileRouteKey) {
                indexOfProfile = verticalMenuItem.length - 1;
            }
        });

        const menuListFormatted: RenderElement[] = [];
        verticalMenuItem.forEach((r, i) => {
            if(i == indexOfProfile) {
                menuListFormatted.push(
                    <React.Fragment key={"dropdown-menu"}>
                        <Dropdown  item={true} icon={"book"}>
                        <Dropdown.Menu>
                            {dropDownMenuItem}
                        </Dropdown.Menu>
                        </Dropdown>
                        {r}
                    </React.Fragment>
                );
                return;
            }

            menuListFormatted.push(r);
        });

        const menu = (
            <Menu size="small" icon="labeled" vertical={true} compact={true}>
                {menuListFormatted}
            </Menu>
        );

        const InternalRoute = (props: RouteProps) => (
            <Route {...props} path={BaseReportPage.rootLocationPath + props.path} />
        );


        const internalRouteList = (
            <>
                <InternalRoute path={"/"} strict={true} exact={true} render={() =>
                    <Redirect to={BaseReportPage.indexRoutePath}/>} />
                <InternalRoute path={"/dashboard"} component={IndexPage} />
                <InternalRoute path={"/users"} component={ManageUsersPage} />
                <InternalRoute path={"/manage-projects"} component={ManageProjectsPage} />
                <InternalRoute path={"/manage-reports/id/:id"} component={ManageReportsPage} />
                <InternalRoute path={"/overview"} component={OverviewPage} />
                <InternalRoute path={"/profile"} component={ProfilePage} />
                <InternalRoute path={"/work-per-unit"} component={WorkPerUnitPage} />
                <InternalRoute path={"/schedules"} component={ManageSchedulesPage} />
                <InternalRoute path={"/report-overview/:prefix?/:id?"} component={ReportOverviewPage} />
                <InternalRoute path={"/company-overview"} component={CompanyOverviewPage} />
                <InternalRoute path={"/personal-report/id/:id"} component={PersonalReportPage} />
                <InternalRoute path={"/charts"} component={ChartPage} />

            </>
        );

        return this.renderWithAuth(
            <Grid divided='vertically'>
                <Grid.Row columns={2}>
                    <Grid.Column width={1}>
                        {menu}
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <Segment>
                            {internalRouteList}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    extractFromPathName() {
        const location = (this.props as any as PropsImpl).location;
        const pathname = location.pathname;

        const indexOf = pathname.indexOf(BaseReportPage.rootLocationPath);
        if(indexOf == -1) {
            return pathname;
        }

        const substr = pathname.substr(BaseReportPage.rootLocationPath.length);
        //console.log(pathname, substr);

        return substr;
    }

    menuItemAsVertical(menuItem: MenuItemProps) {
        return (
            <Menu.Item {...menuItem} onClick={this.onMenuItemClick} />
        );
    }

    menuItemAsDropdown(menuItem: MenuItemProps) {
        return (
            <Dropdown.Item {...menuItem} onClick={this.onMenuItemClick} />
        );
    }
}

export const BaseAdminPage = BaseReportPage;