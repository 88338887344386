import * as React from "react";
import {BasePage} from "../../Components/BasePage";
import {Grid, Header, Form, Segment, Button, Image, Message} from "semantic-ui-react"
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {ApiRequest} from "../../Network/ApiRequest";
import {RouteComponentProps} from "react-router";
import {StorageHelper} from "../../Network/StorageHelper";
import {BaseAdminPage} from "../Report/BaseReportPage";
import {Link} from "react-router-dom";
import {IUser} from "../../Network/ResponseTypes";

enum FormNames {
    email = "email",
    password = "password"
}

interface IProps {

}

interface IState {
    [FormNames.email]: string;
    [FormNames.password]: string;
}

type PropsImpl = IProps & RouteComponentProps<any>;
type StateImpl = IState;
class LoginPageImpl extends BasePage<PropsImpl, StateImpl> {

    constructor(props: PropsImpl) {
        super(props);

        this.state = {email: "", password: ""};
    }

    moveToDashboard = () => {
        this.props.history.push(BaseAdminPage.indexRoutePath);
    };

    componentDidMount() {
        if(StorageHelper.hasAuthKey()) {
            this.moveToDashboard();
        }
    }

    onChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const name = data.name;
        const value = data.value;
        const nextState = {...this.state, [name]: value};

        this.setState(nextState);
    };

    onClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        const moveToDashboard = (email: string, isAdmin: boolean) => {
            StorageHelper.initializeAuthKey();
            StorageHelper.setEmail(email);
            StorageHelper.clearIsAdmin();
            if(isAdmin) {
                StorageHelper.initializeIsAdmin();
            }
            this.moveToDashboard();
        };
        ApiRequest.post<IUser>("/Authentication/Login", {email: this.state.email, password: this.state.password})
        .then(res => moveToDashboard(res.data.email, res.data.isAdmin))
        .catch(rej => this.updateErrorState(rej));
    };

    render() {
        const formData = (
            <>
                <Segment stacked={true}>
                    <Form.Input fluid={true}
                                icon="mail"
                                iconPosition="left"
                                placeholder="E-mail address"
                                onChange={this.onChange}
                                name={FormNames.email}
                                value={this.state.email}/>
                    <Form.Input
                        fluid={true}
                        icon="lock"
                        iconPosition="left"
                        placeholder="Password"
                        type="password"
                        name={FormNames.password}
                        onChange={this.onChange}
                    />

                    <Button onClick={this.onClick} color="teal" fluid={true} size="large">
                        Login
                    </Button>
                </Segment>
            </>
        );
        const preForm = (
            <>
                <Header as="h2" color="teal" textAlign="center">
                    <Image src="/media/images/logo.png" /> Log-in to your account
                </Header>
                {this.renderError()}
            </>
        );
        const postForm = (
            <Segment>
                <Link to={"/password-reset-request"}>Password reset</Link>
            </Segment>
        );
        return this.renderFormGrid(formData, preForm, postForm);
    }
}

export const LoginPage = LoginPageImpl;