import {IGenericKeyedInterface} from "../Base/Semantic";
import * as moment from "moment";
import { DATE_ONLY_FORMAT } from "../Globals";
import * as qs from "qs";
import { IIDEntity } from "../Network/ResponseTypes";
import { IStringKeyObject } from "../Base/AppTypes";

export function boolToString(val: boolean) {
    return val ? "True" : "False"
}

interface IBoolToText {
    isTrue: string;
    isFalse: string;
}


export function getIdForValue(res?: IIDEntity) {
    return res === undefined ? {} : {value: res.id};
}

export function boolToText(val: boolean, {isTrue, isFalse}: IBoolToText) {
    return val ? isTrue : isFalse;
}

//Imperfect
export function padNumberWithZero(val: number, zeroCount: number = 1) {
    const maxValue = Math.pow(10, zeroCount);

    if(val < maxValue) {
        return "0".repeat(zeroCount) + val;
    }

    return val.toString();
}

export interface IMapArrayToKeysResult<T> {
    key: string;
    value: T;
}

type TCallback<T, U> = (value: T, index: number, array: T[]) => IMapArrayToKeysResult<U>;

export function mapArrayToKeys<T, U>(array: T[], callback: TCallback<T, U>) {
    const result: IGenericKeyedInterface<U> = {};
    array.forEach((r, index) => {
        const data = callback(r, index, array);
        result[data.key] = data.value;
    });

    return result;
}

export function unwrapKeysFromArray(array: any[]) {
    const result: any = {};
    array.forEach(r => {
        for(let k in r) {
            result[k] = r[k];
        }
    });

    return result;
}

export function unwrapKeyValueFromObject<T>(obj: IStringKeyObject<T>, apply?: TApplyCallback) {
    const res: T[] = [];
    for(let key in obj) {
        const value = obj[key];
        let nextRes = value;
        if(apply !== undefined) {
            nextRes = apply(value, key);
        }

        res.push(nextRes);
    }

    return res;
}

type TMergeCallback = (dstData: any, srcData: any) => any;
export function mergeKeysWithCallback(dst: any, src: any, mergeCallback?: TMergeCallback) {
    for(let key in src) {
        if(dst.hasOwnProperty(key)) {
            if(mergeCallback !== undefined) {
                dst[key] = mergeCallback(dst[key], src[key]);
            }
        }
        else {
            dst[key] = src[key]
        }
    }
}

//TODO: Purify
type TApplyCallback = (item: any, key: any) => any;
export function applyToEachKey(data: any, apply: TApplyCallback) {
    for(let key in data) {
        data[key] = apply(data[key], key);
    }

    return data;
}

type TFilterCallback = (item: any, key: any) => boolean;
export function applyToFilteredKeys(data: any, filter: TFilterCallback, apply: TApplyCallback) {
    for(let key in data) {
        const value = data[key];
        let canApply = filter(value, key);
        if(!canApply) {
            continue;
        }
        data[key] = apply(value, key);
    }

    return data;
}

export function StringifyDateQuery(start: moment.Moment, end: moment.Moment) {
    return qs.stringify({startDate: start.format(DATE_ONLY_FORMAT), endDate: end.format(DATE_ONLY_FORMAT)});
}

const INTERNAL_HASH_CONSTANT = 0xDEADBEAD;
const HASH_CUT = 0x00FFFFFF;
export function simpleFastHash(value: string) {
    let hashValue = 7;
    let index = 1;
    for(let key of value) {
        const intVal = key.charCodeAt(0);

        hashValue += intVal * 17 + index;
        index++;
        //console.log(hashValue);
    }

    
    return hashValue & HASH_CUT;
}

const SIZE_OF_COLOR_STR = 6;
export function numberToColor(value: number) {
    if(value < 0) {
        value = Math.abs(value);
    }
    const hexStr = value.toString(16);
    //console.log(hexStr);
    const finalSize = SIZE_OF_COLOR_STR - hexStr.length;
    const padding = new Array(finalSize).fill("0").reduce((acc, val) => acc + val, "");
    return `#${padding}${hexStr}`;
}

