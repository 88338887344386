import * as React from "react";
import { ExtendedTable, IBodyContainer, IHeaderCell } from "../../Components/ExtendedTable";
import { ApiRequest } from "../../Network/ApiRequest";
import * as moment from "moment";
import { DATE_ONLY_FORMAT, DATE_ONLY_NO_DAY } from "../../Globals";
import { mapArrayToKeys, padNumberWithZero } from "../../Utils/Helpers";
import { makeHeaderCell, mapBodyByHeader } from "../../Utils/TableUtils";
import { BasePage } from "../../Components/BasePage";
import { DateTable } from "../../Components/DateTable";
import { getDayFromDateOnlyString } from "../../Utils/DateTimeUtils";
import { AdvancedDateTable } from "../../Components/AdvancedDateTable";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, PieChart, Pie, Text, BarChart, Bar, LabelProps } from "recharts";
import { Segment, Button } from "semantic-ui-react";
import { BaseReportTimePage, IDateControlDataContainer, IPreparedDateControlData } from "../../Components/BaseReportTimePage";
import { IDataProps } from "../../Base/Semantic";


enum ViewStates {
    None = "none",
    ProjectsWithMostTime = "projectsWithMostTime",
    UsersWithMostTime = "usersWithMostTime"
}

const ViewStatesKeys = Object.keys(ViewStates);
const ViewStateValues = ViewStatesKeys.map(v => ViewStates[v as any]);
interface IProps {

}

interface IState extends IDateControlDataContainer {
    data: any;
    viewState: ViewStates.None
}

interface IViewStateData {
    title: string;
    method: string | null;
    renderMethod: Function;
}

function makeState(title: string, method: string | null, renderMethod: Function): IViewStateData {
    return {title, method, renderMethod};
}

const ViewStateMap: {[key: string]: IViewStateData} = {
    [ViewStates.None]: makeState("None", null, () => null),
    [ViewStates.ProjectsWithMostTime]: makeState("Highest worked on projects", "ProjectsWithMostTime", PieChartRenderer),
    [ViewStates.UsersWithMostTime]: makeState("Highest worked users",  "UsersWithMostTime", BarChartRenderer)
};



let SharedWidth = 1024;
let SharedHeight = 768;
type PropsImpl = IProps;
type StateImpl = IState;
class ChartPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);
        this.state = {data: [], dateControlData: this.getDefaultDateControlState(),
        viewState: ViewStates.None};
    }

    dateControlChange(data: IPreparedDateControlData) {
        this.fetchData(data);
    }

    fetchData(data: IPreparedDateControlData) {
        const stateData = this.getStateData();
        
        if(stateData.method === null) {
            this.setState({data: []});
            return;
        }

        const route = `/Charts/${stateData.method}?${data.queryString}`;
        ApiRequest.get<any>(route).then(r => {
            this.setState({data: r.data, dateControlData: data});
        })
    }

    getStateData() {
        return ViewStateMap[this.state.viewState];
    }

    onClick = (_: any, params: IDataProps) => {
        const name = params.name;
        if(name === undefined) {
            return;
        }

        this.setState({viewState: name} as any, () => {
            //Semms like a bad practice
            this.dateControlChange(this.state.dateControlData);
        });
    };

    render() {
        SharedWidth = window.innerWidth * 0.75;
        SharedHeight = window.innerHeight * 0.5;
        const {data} = this.state;

        const stateData = this.getStateData();

        let renderResult = null;
        if(data.length != 0) {
            renderResult = stateData.renderMethod(data);
        }

        const stateMapControl = ViewStateValues.map(v => {
            const stateData = ViewStateMap[v];

            return (
                <Button key={v} content={stateData.title} onClick={this.onClick} name={v} />
            );
        });

        return this.renderWithDateControl(
            <Segment>
                <Segment>
                    {stateMapControl}
                </Segment>
                {renderResult}
            </Segment>
        );
    }
}

export const ChartPage = ChartPageImpl;

function PieChartLabel(data: any, params: any): React.ReactNode {
    const {percent, index} = params;
    const info = data[index];
    const title = info.name;
    const hours = (info.minutes / 60).toFixed(2);
    const percentValue = (percent * 100).toFixed(2);

    return `${title}: ${hours}(${percentValue}%)`;
}

function PieChartRenderer(data: any) {
    return (
        <PieChart width={SharedWidth} height={SharedHeight}>
            <Pie data={data} dataKey="minutes" nameKey="name" outerRadius={96} fill="#629833" label={(params) => PieChartLabel(data, params)} />
            <Legend />
        </PieChart>
    );
}

function BarChartLabel(data: LabelProps) {
    return `test`;
}

function BarChartRenderer(data: any) {
    return (
        <BarChart width={SharedWidth} height={SharedHeight} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="minutes" fill="#8884d8"  />
        </BarChart>
    )
}