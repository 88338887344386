import {AxiosResponse} from "axios";

export interface IApiResponse<T> {
    data: T;
    meta: AxiosResponse<T>;
    errors: IError[];
}

export interface IError {
    id: number;
    title: string;
    details: string;
}

export interface IErrorState {
    errors?: IError[];
}

export enum RequestType {
    Get = "get",
    Post = "post",
    Put = "put",
    Patch = "patch",
    Delete = "delete"
}

export enum Protocol {
    None = "None",
    Auto = "auto",
    Http = "http",
    Https = "https"
}
