import * as React from "react"
import * as ReactDom from "react-dom"
import {BrowserRouter} from "react-router-dom"
import {RouteManager} from "./RouteManager";
import "./Styles/Main.scss";



const Root = document.querySelector("#Root");

const Render =
    <BrowserRouter>
        <RouteManager />
    </BrowserRouter>;
ReactDom.render(Render, Root);