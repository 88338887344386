import * as React from "react";
import { ExtendedTable, IBodyContainer, IHeaderCell } from "../../Components/ExtendedTable";
import { ApiRequest } from "../../Network/ApiRequest";
import * as moment from "moment";
import { DATE_ONLY_FORMAT, DATE_ONLY_NO_DAY } from "../../Globals";
import { mapArrayToKeys, padNumberWithZero, StringifyDateQuery } from "../../Utils/Helpers";
import { makeHeaderCell, mapBodyByHeader, prepareHeaderCellsFromDateStringArray, makeExpressTable } from "../../Utils/TableUtils";
import { BasePage } from "../../Components/BasePage";
import { DateTable } from "../../Components/DateTable";
import { getDayFromDateOnlyString, makeStringDateRange, transformMinutesToHoursText } from "../../Utils/DateTimeUtils";
import { AdvancedDateTable } from "../../Components/AdvancedDateTable";
import { DateControl, TOnDateChange } from "../../Components/DateControl";
import { BaseReportTimePage, IPreparedDateControlData, IDateControlDataContainer } from "../../Components/BaseReportTimePage";
import { IIssueUserMinutes, IIssueMinutes, IProject, IUser, IPersonalReport, IProjectIssueMinutes } from "../../Network/ResponseTypes";
import { Header, Segment } from "semantic-ui-react";

interface IProps {

}

interface IState extends IDateControlDataContainer {
    data: IPersonalReport[];
}

type PropsImpl = IProps;
type StateImpl = IState;
class CompanyOverviewPageImpl extends BaseReportTimePage<PropsImpl, StateImpl> {
    constructor(props: PropsImpl) {
        super(props);
        const today = moment();
        this.state = { data: [], dateControlData: this.getDefaultDateControlState(today.clone(), today.clone()) };
        this.singleMode = true;
    }

    componentDidMount() {

    }

    dateControlChange(dateData: IPreparedDateControlData) {
        //console.log(data);
        this.fetchData(dateData);
    }

    fetchData(data: IPreparedDateControlData) {
        ApiRequest.get<IPersonalReport[]>(`/Report/CompanyOverview?${data.queryString}`).then(r => {
            this.setState({ data: r.data, dateControlData: data });
        });
    }

    buildReport(personalReport: IPersonalReport) {
        const header = <Header>{personalReport.user.name} - {transformMinutesToHoursText(personalReport.totalSpentMinutes)}</Header>;
        const result = personalReport.projectIssueMinutes.map(v => this.buildProjectInfo(v));

        return (
            <Segment key={personalReport.user.id}>
                {header}
                <hr />
                {result}
            </Segment>
        );
    }

    buildProjectInfo(projectIssueMinutes: IProjectIssueMinutes) {
        const result = projectIssueMinutes.issueMinutes.map((v, i, arr) => {
            return this.buildIssue(v);
        });

        const headers = [makeHeaderCell("meta", "Issue"), makeHeaderCell("duration", "Time")];
        return (
            <React.Fragment>
                <Header>{projectIssueMinutes.project.name} - {transformMinutesToHoursText(projectIssueMinutes.totalSpentMinutes)}</Header>
                {makeExpressTable(headers, result)}
            </React.Fragment>
        )
    }

    buildIssue(issueMinutes: IIssueMinutes) {
        return {meta: issueMinutes.issue.title, duration: transformMinutesToHoursText(issueMinutes.totalSpentMinutes)};
    }

    render() {
        const result = this.state.data.map(r => this.buildReport(r));

        return this.renderWithDateControl(<>{result}</>, this.state.dateControlData);
    }
}

export const CompanyOverviewPage = CompanyOverviewPageImpl;